import React, { Component } from 'react';
import * as Table from 'reactabular-table';
import { translate } from 'react-switch-lang';

class NDFBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows:[
            ]
        };
        this.styleTransform = this.styleTransform.bind(this)
    }


  styleTransform = (side) => {
    if(side === 'Авах')
    return { className: 'sell' }
    else
    return { className: 'buy' }
  }

    render() {
      const { t } = this.props;

        const columns = [
            {
                property: 'ccy',
                header: {
                    label: t('order.ccy'),
                }
            },
            {
                property: 'side',
                header: {
                  label: t('order.side'),
                },
                cell: {
                  transforms: [
                    value => this.styleTransform(value)
                  ]
                },
              },
            {
                property: 'price',
                header: {
                  label: t('order.price'),
                },
              },
              {
                property: 'size',
                header: {
                  label: t('order.amount'),
    
                },
              },
            {
                property: 'valuedate',
                header: {
                    label: t('order.valuedate'),
                },
            },
            {
                property: 'response',
                header: {
                    label: t('rfq.response'),
                },
                cell:{
                  formatters: [
                    (userid) => (
                      <div className="rfq-chng">
                        <button type="button" className="btn btn-primary btn-sm btn-sell"  data-toggle="modal" data-target="#rfqmodal">{t('rfq.response')}</button>
                        
                      </div> 
                    )
                  ]
                }
            },
        ];

        // const rows = [{ccy:'USD',side:'Авах',price:2500.75,size:1000000,valuedate:"2019-06-15"},{ccy:'USD',side:'Зарах',price:400.75,size:5000000,valuedate:"2019-07-15"}]
        const rows = [];
        return (
            <div className="component ndf-component">
                <Table.Provider
                    className="rfq-table"
                    columns={columns}>
                    <Table.Header />
                    <Table.Body rows={rows} rowKey="id" />
                </Table.Provider>
            </div>
        );
    }

}

export default translate(NDFBoard);