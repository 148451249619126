import axios from 'axios';
import { GET_USER, GET_USER_ACCOUNTS, CREATE_USER } from './action_types';

export const getUser = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/user')
    .then(res => {
        dispatch(GetUser(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const getUserAccounts = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/user/accounts')
    .then(res => {
        dispatch(GetUserAccounts(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const createUser = user => dispatch =>{
    axios.post('https://prod.api.bourse.mn/admin/createuser', user)
    .then(res => {
        console.log(res);
        dispatch(CreateUser(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const sendWelcomeEmail = user => dispatch =>{
    axios.post('https://prod.api.bourse.mn/order/authEmail', user)
    .then(res => {
        console.log(res);
        dispatch(CreateUser(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const CreateUser = user => {
    return {
        type: CREATE_USER,
        payload: user
    }
}

export const GetUser = user => {
    return {
        type: GET_USER,
        payload: user
    }
}

export const GetUserAccounts = accounts => {
    return {
        type: GET_USER_ACCOUNTS,
        payload: accounts
    }
}