/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import {connect} from 'react-redux';
import {logoutUser, changeTheme} from '../../actions/authentication';
import {withRouter} from 'react-router-dom'
import {
  setLanguage,
  translate,
  getLanguage
} from 'react-switch-lang';

export class Header extends Component {

  constructor(props) {
    super(props)
    this.state={
      english: getLanguage() === 'en',
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.auth.isAuthenticated){
        this.props.history.push('/login');
    }
  }

  logout = e => {
    e.preventDefault();
    this.props.logoutUser(this.props.history);
  }

  onAddressChanged = (e) => {
    this.props.changeTheme(e.currentTarget.value);
  }

  changeLanguage= (e) => {
    const key = !this.state.english ? 'en': 'mon';
    setLanguage(key);
    localStorage.setItem('language',key);
    this.setState({english: !this.state.english});
  }
  render() {
    const { t } = this.props;

    const logout = this.props.auth.isAuthenticated ? <button className="btn-logout dropdown-item" type="button" onClick={this.logout}> <i className="header-icon fas fa-sign-out-alt"></i> {t('menu.signout')}</button> : '';

    const userMenu = this.props.auth.isAuthenticated ?
      <div className="nav-item dropdown">
      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fas fa-user"></i>
      </a>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
        {
          this.props.auth.user.user_id === '1' || this.props.auth.user.user_id === 'a05396c5-f244-4a13-9e7f-91e38b86279a'  ?
          <a className="dropdown-item" href="admin"> <i className="header-icon fas fa-user-shield"></i>Админ</a> :
          <a className="dropdown-item" href="profile"> <i className="header-icon fas fa-user-cog"></i>{t('menu.profile')}</a>
        }
        <div className="dropdown-item">
          <input type="radio" name="theme" value="black-theme" checked={this.props.theme === 'black-theme'} onChange={this.onAddressChanged} /> {t('menu.dark')} <br/>
          <input type="radio" name="theme" value="light-theme" checked={this.props.theme === 'light-theme'} onChange={this.onAddressChanged} /> {t('menu.light')}
        </div>
        <hr/>
        {logout}
      </div>
    </div> : '';
    const activeLanguage = this.state.english ? 'flag-icon-gb': 'flag-icon-mn';
    const inActiveLanguage = this.state.english ? 'flag-icon-mn': 'flag-icon-gb';

return (
      <div className="width100">
      <nav className="navbar navbar-expand-md navbar-dark  header-dash width100">
            <a className="navbar-brand" href="/">
              <img src="/images/main-logo.png" alt="Logo" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav">
                <div className="header-price">2,849.77<span>USDMNT</span></div>
                <div className="header-price">443.08<span>CNHMNT</span></div>
                <div className="header-price">3,452.78
                <span>EURMNT</span></div>
                <div className="header-price ">26.05
                <span>JPYMNT</span></div>
              </div>
              <div className="navbar-nav navbar-right ml-auto">
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="dropdown09" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className={"flag-icon " + activeLanguage}> </span></a>
                    <div className="dropdown-menu languagedropdown" aria-labelledby="dropdown09">
                        <a onClick={this.changeLanguage} className="dropdown-item padding10"><span className={"flag-icon " + inActiveLanguage}> </span>  </a>
                    </div>
                </li>
                {userMenu}
              </div>
            </div>
      </nav>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  theme: state.auth.theme
})

export default connect (mapStateToProps, {logoutUser, changeTheme}) (withRouter(translate(Header)));