import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import toCurrency from '../../utils/currency'
import { translate } from 'react-switch-lang';

class TradeSummary extends Component {

  constructor(props) {
    super(props);

      this.state = {
          currentTrade : {}
      };
  }

  convertSide = (side,userid, userid2) => {
    const { t, user_id } = this.props;
    if(user_id === userid2) {
      if (side === "BUY")
        return <td className="sell">{t('summary.buy')}</td>
      return <td className="buy">{t('summary.sell')}</td>
    }
    else if (user_id === userid) {
      if (side === "BUY")
      return <td className="buy">{t('summary.sell')}</td>
    return <td className="sell">{t('summary.buy')}</td>
    }
    return <td></td>;
  }

  convertPrice = (side, userid, userid2, price, ccy ) => {
    const commision = ccy === 'USD' ? 0.25 : 0.10;
    const { t, user_id } = this.props;
    if(user_id === userid2) {
      if(side === 'BUY')
       return <td>{toCurrency(parseFloat(price) + commision)}</td>
      return <td>{toCurrency(parseFloat(price) - commision)}</td>
    }
    return <td>{toCurrency(price)}</td>
  }

  convertFile = (side, userid, userid2, price ) => {
    const { t, user_id } = this.props;
    if(side === 'BUY') {
      if(user_id === userid)
        return "https://prod.api.bourse.mn/" + this.state.currentTrade.id + "-buyer.pdf";
      else if(user_id === userid2)
        return "https://prod.api.bourse.mn/" + this.state.currentTrade.id + "-seller.pdf";
    }
    else if( side === 'SELL')
    {
      if(user_id === userid)
        return "https://prod.api.bourse.mn/" + this.state.currentTrade.id + "-seller.pdf";
      else if(user_id === userid2)
        return "https://prod.api.bourse.mn/" + this.state.currentTrade.id + "-buyer.pdf";
    }
  }

  convertStatus = status => {
    const { t } = this.props;

    if(status === "CANCELLED")
    return t('summary.cancelled')
    return t('summary.confirmed')
  }

  render() {
    const { trades } = this.props;
    const { t } = this.props;
    
    return (
      <div className="component-tab">
          <table>
          <thead>
            <tr>
              <th>{t('order.ccy')}</th>
              <th>{t('order.instrument')}</th>
              <th>{t('order.side')}</th>
              <th>{t('order.price')}</th>
              <th>{t('order.amount')}</th>
              <th>{t('order.valuedate')}</th>
              <th>{t('order.status')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {trades.map(trade => {
            return (
              <tr key={trade.id}>
                <td>{trade.ccy}</td>
                <td>{t('order.spot')}</td>
                {this.convertSide(trade.side, trade.makerid, trade.takerid)}
                {this.convertPrice(trade.side, trade.makerid, trade.takerid, trade.price, trade.ccy)}
                <td>{toCurrency(trade.size)}</td>
                <td>{trade.valuedate.slice(0, 10)}</td>
                <td>{t('summary.confirmed')}</td>
                <td>
                <i data-toggle="modal" data-target="#instructionModal" onClick={() => this.setState({currentTrade: trade})} className="fas fa-file"></i>
                </td>
              </tr>
            )}
          )}
          </tbody>
        </table>
        <div className="modal" id="instructionModal">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">{t('summary.paymentslip')}</h5>
                    </div>
                    <div className="modal-body">
                      <iframe title="pdf" src={this.convertFile(this.state.currentTrade.side, this.state.currentTrade.makerid, this.state.currentTrade.takerid)} style={{ width:'100%', height: '100%' }} frameBorder="0"></iframe>
                    </div>
                </div>
            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  trades: state.user.trades,
  user_id: state.auth.user.user_id
})
export default connect(mapStateToProps, { })(translate(TradeSummary));