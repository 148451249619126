export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const ERROR_USER = 'ERROR_USER';

export const NEW_RFQ = 'NEW_RFQ';
export const RFQ_LIST = 'RFQ_LIST'
export const RFQ_ADD = 'RFQ_ADD';
export const USER_ORDERS = 'USER_ORDERS';
export const USER_ORDERS_ADD = 'USER_ORDERS_ADD';

export const ACCOUNT_BALANCE = 'ACCOUNT_BALANCE';
export const TWO_FACTOR = 'TWO_FACTOR';

//v2
export const GET_USER = 'GET_USER';
export const CREATE_RFQ = 'CREATE_RFQ';
export const GET_RFQ = 'GET_RFQ';
export const GET_QUOTE = 'GET_QUOTE';
export const CREATE_QUOTE = 'CREATE_QUOTE';
export const CONFIRM_QUOTE = 'CONFIRM_QUOTE';
export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const CREATE_TRADE = 'CREATE_TRADE';



export const CANCEL_RFQ = 'CANCEL_RFQ';

export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const CHANGE_THEME = 'CHANGE_THEME';
export const GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS';

export const CREATE_USER = 'CREATE_USER';
export const CHANGE_STATUS = 'CHANGE_STATUS';




