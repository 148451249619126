import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../actions/authentication';
import { changePassword } from '../actions/usermanagement';
import { withLocalize } from "react-localize-redux";
import { store } from 'react-notifications-component';
import { getUser, getUserAccounts } from '../actions/v2user';
import $ from "jquery";

class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            errors: {}
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const user = {
            pass: this.state.password,
        }
        
        this.props.changePassword(user);
        $("#changePasswordModal").modal("hide");

        store.addNotification({
            title: "Амжилттай",
            message: "Нууц үг амжилттай солигдлоо.",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
        });
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

        if(!nextProps.auth.isAuthenticated){
            this.props.history.push('/login');
        }
    }

    componentDidMount(){
		if(!this.props.auth.isAuthenticated) {
			this.props.history.push('/login');
        }
        this.props.getUserAccounts()

	}

    render() {
        const users = [];
        console.log(this.props.accounts);
        return(
            <div>
                <div className="row place-order">                    
                    {/* <button type="submit" className="btn btn-place-order-login-2" data-toggle="modal" data-target="#changePasswordModal">Данс нэмэх</button> */}

                    <button type="submit" className="btn btn-place-order-login-2" data-toggle="modal" data-target="#changePasswordModal">Нууц үг солих</button>
                </div>
            
                <div className="component-tab">
                    <table>
                    <thead>
                        <tr>
                        <th>Банк</th>
                        <th>Дансны дугаар</th>
                        <th>Валют</th>
                        <th>Статус</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.props.accounts.map(user => {
                        return (
                        <tr key={user.AccountNumber}>
                            <td>{user.BankName}</td>
                            <td>{user.AccountNumber}</td>
                            <td>{user.Ccy}</td>
                            {
                            user.status === 'inactive' ? 
                            <div className="rfq-chng">
                            <button type="button" className="btn btn-primary btn-sm btn-sell">Имэйл илгээх</button>
                            </div> :
                            <td>Идэвхитэй</td>
                            }
                        </tr>
                        )}
                    )}
                    </tbody>
                    </table>
                </div>

            <div className="modal" id="changePasswordModal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Нууц үг солих</h5>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={ this.handleSubmit } className="buy-sell-form white-form">
                                <div className="form-group">
                                    <hr/>
                                    <div className="row">
                                        <div className="col">
                                            <label>Хуучин нууц үг</label>
                                            <input type="password" autoComplete="off" name="email"
                                onChange={ this.handleInputChange }
                                value={ this.state.email } className="form-control form-control-sm" id="Price"  />
                                            <label>Шинэ Нууц үг</label>
                                            <input type="password" autoComplete="off" name="password"
                                onChange={ this.handleInputChange }
                                value={ this.state.password } className="form-control form-control-sm" id="Price2" />
                                <label>Шинэ Нууц үг Баталгаажуулах</label>
                                            <input type="password" autoComplete="off" name="passwordconfirm"
                                onChange={ this.handleInputChange }
                                value={ this.state.passwordconfirm } className="form-control form-control-sm" id="Price2" />
                                        </div>
                                    </div>
                                    <div className="row place-order">                    
                                        <button type="submit" className="btn btn-place-order-login" data-toggle="modal" data-target="#changePasswordModal">Хадгалах</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

Profile.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    theme: state.auth.theme,
    accounts: state.user.accounts
})

export  default connect(mapStateToProps, { loginUser,changePassword , getUserAccounts})(withLocalize(Profile))