import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import toCurrency from '../../utils/currency'
import { store } from 'react-notifications-component';
import { send2FA } from '../../actions/order';
import { confirmQuote } from '../../actions/v2order';
import { translate } from 'react-switch-lang';
import $ from "jquery";

class MyRfqBoard extends Component {
  constructor(props) {
    super(props);

    this.state = {
        twofa: false,
        code: "",
        quoteId : "",
        quote: {},
        currentRfq: {}

    };
    this._handleChange = this._handleChange.bind(this)
  }

  // componentDidMount = () => {
  //   $("#confirmModal").modal("show"); 
  // }


  _handleChange(e) {
    let {name, value} = e.target;
    this.setState({
    [name]: value,
    });
  }

  placeorder = () => {
    const commision = this.state.currentRfq.ccy === 'USD' ? 0.25 : 0.10;

    var item = {  
        ccy: this.state.currentRfq.ccy,
        price: this.state.currentRfq.side === 'BUY' ? toCurrency(parseFloat(this.state.quote.price) + commision) : toCurrency(parseFloat(this.state.quote.price) - commision),
        size: this.state.quote.size,
        side: this.state.currentRfq.side
    };
    const { t } = this.props;


    if( !this.state.twofa ) {
        this.props.send2FA(item);
        store.addNotification({
            message: t('order.emailcode'),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
            duration: 3000,
            onScreen: true
            }
        });
        this.setState( {twofa: true} )
    }

    else {
        if(this.state.code === this.props.code){
          console.log(this.state.quoteId);
          
            this.props.confirmQuote(this.state.quoteId);
            $("#confirmModal").modal("hide"); 

        }
        else{
            store.addNotification({
                message: t('order.wrongcode'),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
        }
    }
  }


  convertSide = side => {
	  const { t } = this.props;
    if(side === "BUY")
    return <td className="sell">{t('order.buy')}</td>
    return <td className="buy">{t('order.sell')}</td>
  }

  render() {
    const { rfq, quote, user } = this.props;
    const commision = this.state.currentRfq.ccy === 'USD' ? 0.25 : 0.10;

    const filter = rfq.filter(x => x.userid === user.user_id)
    
    const quotedrfqs = filter.map(r => {
      let array = quote.filter(x => x.rfqid === r.id && x.status === "OPEN")
      const pair = {quotes: array}
      r = {...r, ...pair}
      return r
    });
	  const { t } = this.props;
    
    const twofa = this.state.twofa ? 
    <div className="row justify-content-center">
        <div className="col-md-5">
            <label>{t('order.twofactor')}</label>
            <input 
            type="text" 
            onChange={this._handleChange} 
            autoComplete="off"  
            name="code" 
            className="form-control form-control-sm" 
            id="code" 
            placeholder={t('rfq.email123')} />
        </div>
    </div>: <div/>;

		return (
      <div className="component">
        <table className="rfq-table">
          <thead>
            <tr>
              <th>{t('order.ccy')}</th>
              <th>{t('order.instrument')}</th>
              <th>{t('order.side')}</th>
              <th>{t('order.price')}</th>
              <th>{t('order.amount')}</th>
              <th>{t('order.valuedate')}</th>
              <th>{t('rfq.response')}</th>
            </tr>
          </thead>
          <tbody>
          {quotedrfqs.map(rfq => {
            const commision = rfq.ccy === 'USD' ? 0.25 : 0.10;
            return (
              <>
                <tr key={rfq.id}>
                  <td>{rfq.ccy}</td>
                  <td>{t('order.spot')}</td>
                  {this.convertSide(rfq.side)}
                  <td>{toCurrency(rfq.price)}</td>
                  <td>{toCurrency(parseFloat(rfq.size) - parseFloat(rfq.filled)) + '(' + toCurrency(rfq.size) + ')'}</td>
                  <td>{rfq.valuedate.slice(0, 10)}</td>
                  <td></td>
                </tr>
                {rfq.quotes.map(q => (
                  <tr className="quote" key={q.id}>
                    <td></td>
                    <td></td>
                    <td></td>
                    {rfq.side === 'BUY' ? <td>{toCurrency(parseFloat(q.price) + commision)}</td> : <td>{toCurrency(parseFloat(q.price) - commision)}</td>}
                    <td>{toCurrency(q.size)}</td>
                    <td>{rfq.valuedate.slice(0, 10)}</td>
                    <td>
                      <div className="rfq-chng">
                        <button type="button" className="btn btn-primary btn-sm btn-sell"  data-toggle="modal" data-backdrop="static" data-keyboard="false" onClick={() => this.setState({quoteId: q.id, quote: q, currentRfq: rfq})} data-target="#confirmModal">{t('order.confirm')}</button>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          )}
          </tbody>
        </table>
        <div className="modal" id="confirmModal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t('order.confirmquote')}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-5 confirm-main">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.ccy')}</p>
                                        <p className="value-text">{this.state.currentRfq.ccy}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.amount')}</p>
                                        <p className="value-text">{toCurrency(this.state.quote.size)}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.price')}</p>
                                        {this.state.currentRfq.side === 'BUY' ? <p className="value-text">{toCurrency(parseFloat(this.state.quote.price) + commision)}</p> : <p className="value-text">{toCurrency(parseFloat(this.state.quote.price) - commision)}</p> }
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">Чат</p>
                                        <input 
                                        type="text" 
                                        onChange={this._handleChange} 
                                        autoComplete="off"  
                                        name="chat" 
                                        value={this.state.description}
                                        className="form-control form-control-sm" 
                                        id="chat" 
                                        placeholder="Чат" />
                                    </div>
                                </div>
                                <div className="col-md-5 confirm-sub">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.instrument')}</p>
                                        <p className="value-text">{t('order.spot')}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.valuedate')}</p>
                                        <p className="value-text">{this.state.currentRfq.valuedate && this.state.currentRfq.valuedate.slice(0, 10)}</p>
                                    </div>
                                </div>
                            </div>
                            {twofa}
                            <div className="row">
                                <button type="button" className="btn btn-place-order-cancel" data-toggle="modal" data-target="#confirmModal" onClick={() => this.setState({twofa: false})}>{t('order.cancel')}</button>
                                <button type="button" className="btn btn-place-order-confirm" data-toggle="modal" onClick={this.placeorder}>{t('order.confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			</div>
    );
  }
}

const mapStateToProps = (state) => ({
  rfq: state.rfq.rfqs,
  quote: state.rfq.quotes,
  user: state.auth.user,
  code: state.auth.code
})
export default connect(mapStateToProps, {send2FA, confirmQuote})(translate(MyRfqBoard));