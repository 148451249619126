import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { getUser } from '../../actions/v2user';
import toCurrency from '../../utils/currency'

class AccountBalance extends Component {

  componentDidMount(){
  }

  withdraw = () => {
  }

  render() {
    const { balance, orders, trades} = this.props;

    let active = {mnt: 0 , usd: 0, cnh: 0 , eur: 0 , jpy: 0}
    orders.filter(o => o.status === "OPEN" ).forEach(o => {
      if(o.side === "BUY")
      active.mnt += parseFloat(o.price) * parseFloat(o.size)
      else{
        if(o.ccy === "USD")
        active.usd += parseFloat(o.size)
        if(o.ccy === "CNH")
        active.cnh += parseFloat(o.size)
        if(o.ccy === "EUR")
        active.eur += parseFloat(o.size)
        if(o.ccy === "JPY")
        active.jpy += parseFloat(o.size)
      }
    });

    trades.filter(o => o.status === "CONFIRMED" ).forEach(o => {
      if(o.side === "BUY")
      active.mnt += parseFloat(o.price) * parseFloat(o.size)
      else{
        if(o.ccy === "USD")
        active.usd += parseFloat(o.size)
        if(o.ccy === "CNH")
        active.cnh += parseFloat(o.size)
        if(o.ccy === "EUR")
        active.eur += parseFloat(o.size)
        if(o.ccy === "JPY")
        active.jpy += parseFloat(o.size)
      }
    });

    return (
        <div className="component-tab">
          <table>
            <thead>
                <tr>
                <th></th>
                <th>Боломжит үлдэгдэл</th>
                <th>Нийт үлдэгдэл</th>
                </tr>
            </thead>
            <tbody>
              {balance.map(ccy => {
                return (
                  <tr key={ccy.ccy}>
                    <td>{ccy.ccy.toUpperCase()}</td>
                    <td>{toCurrency(ccy.available)}</td>
                    <td>{toCurrency(ccy.total)}</td>
                    <td> 
                      <div className="rfq-chng">
                        <button type="button" className="btn btn-primary btn-sm btn-sell"  onClick={this.withdraw}>Шилжүүлэх</button>
                      </div>
                    </td>
                  </tr>
                )}
              )}
            </tbody>
          </table>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  balance: state.user.balance,
  orders: state.user.orders,
  trades: state.user.trades,
  user: state.auth.user
})
export default connect(mapStateToProps, {getUser})(AccountBalance);