import axios from 'axios';
import { CREATE_RFQ, GET_RFQ, GET_QUOTE , CANCEL_RFQ, CONFIRM_QUOTE, CREATE_ORDER, GET_ORDER, CREATE_QUOTE} from './action_types';

export const createRfq = (rfq) => dispatch =>{
    axios.post('https://prod.api.bourse.mn/v2/rfq', rfq)
    .then(res => {
        dispatch(CreateRfq(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const createQuote = (quote) => dispatch =>{
    axios.post('https://prod.api.bourse.mn/v2/quote', quote)
    .then(res => {
        dispatch(CreateQuote(res.data.message));
        console.log(res.data.message);
    })
    .catch(err => {
        console.log(err);
    });
}

export const getRfq = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/rfq')
    .then(res => {
    dispatch(GetRfq(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const getQuote = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/quote')
    .then(res => {
        dispatch(GetQuote(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const getOrder = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/order')
    .then(res => {
        dispatch(GetOrder(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const cancelOrder = (id) => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/cancel/'+id)
    .then(res => {
        dispatch(CancelOrder(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const confirmQuote = (id) => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/confirm/'+id)
    .then(res => {
        dispatch(ConfirmQuote(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const createOrder = (order) => dispatch =>{
    axios.post('https://prod.api.bourse.mn/v2/order', order)
    .then(res => {
        dispatch(CreateOrder(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const ConfirmQuote = quote => {
    return {
        type: CONFIRM_QUOTE,
        payload: quote
    }
}

export const CancelOrder = rfq => {
    return {
        type: CANCEL_RFQ,
        payload: rfq
    }
}

export const GetRfq = rfq => {
    return {
        type: GET_RFQ,
        payload: rfq
    }
}

export const GetOrder = order => {
    return {
        type: GET_ORDER,
        payload: order
    }
}

export const GetQuote = quote => {
    return {
        type: GET_QUOTE,
        payload: quote
    }
}

export const CreateRfq = rfq => {
    return {
        type: CREATE_RFQ,
        payload: rfq
    }
}

export const CreateQuote = quote => {
    return {
        type: CREATE_QUOTE,
        payload: quote
    }
}

export const CreateOrder = order => {
    return {
        type: CREATE_ORDER,
        payload: order
    }
}