import axios from 'axios';
import { USER_ORDERS, USER_ORDERS_ADD, GET_ERRORS, CHANGE_STATUS} from './action_types';

export const getUser = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/v2/user')
            .then(res => {
                dispatch(UserOrders(res.data.message));
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                });
            });
}

export const changePassword = (password) => dispatch =>{
    axios.post('https://prod.api.bourse.mn/order/changepassword',password)
            .then(res => {
                console.log(res.data.message);
                dispatch(changeStatus());
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                });
            });
}

export const changeStatus = () => {
    return {
        type: CHANGE_STATUS,
        payload: ""
    }
}

export const UserOrders = orders => {
    return {
        type: USER_ORDERS,
        payload: orders
    }
}

export const UserOrdersAdd = order => {
    return {
        type: USER_ORDERS_ADD,
        payload:order
    }
}