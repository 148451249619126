import { CREATE_ORDER, GET_ORDER, CANCEL_RFQ } from "../actions/action_types";

const initialState = {
    clob:{
        usdbuy:[],
        usdsell: [],
        cnhbuy:[],
        cnhsell:[], 
        eurbuy:[],
        eursell:[],
        jpynuy:[],
        jpysell:[]
    },
}

export default function(state = initialState, action){
    
    switch(action.type){
        case CREATE_ORDER:
            let sellusd = state.clob.usdsell;
            let buyusd = state.clob.usdbuy;
            let sellcnh = state.clob.cnhsell;
            let buycnh = state.clob.cnhbuy;

        
            if(action.payload.ccy === 'USD' && action.payload.side === 'BUY'){
                sellusd = [...sellusd, action.payload]
            }
            if(action.payload.ccy === 'USD' && action.payload.side === 'SELL'){
                buyusd = [...buyusd, action.payload]
            }
            if(action.payload.ccy === 'CNH' && action.payload.side === 'BUY'){
                sellcnh = [...sellcnh, action.payload]
            }
            if(action.payload.ccy === 'CNH' && action.payload.side === 'SELL'){
                buycnh = [...buycnh, action.payload]
            }
            console.log(sellusd);
            return {
                ...state,
                clob:{
                    usdsell: sellusd,
                    usdbuy:buyusd,
                    cnhsell:sellcnh,
                    cnhbuy:buycnh
                }
            }
            case CANCEL_RFQ:
                if(action.payload.ccy === 'USD' && action.payload.side === 'BUY'){
                    sellusd = state.clob.usdsell.map( o => o.id === action.payload.id ? action.payload : o)
                    buyusd = state.clob.usdbuy
                }
                if(action.payload.ccy === 'USD' && action.payload.side === 'SELL'){
                    buyusd = state.clob.usdbuy.map( o => o.id === action.payload.id ? action.payload : o)
                    sellusd = state.clob.usdsell
                }
                return {
                    ...state,
                    clob:{
                        usdsell: sellusd,
                        usdbuy:buyusd,
                        cnhsell:state.clob.cnhsell,
                        cnhbuy:state.clob.cnhbuy
                    }
                }
            case GET_ORDER:
                console.log(action.payload);
                let getsellusd = action.payload.filter(x => x.side === 'BUY' && x.ccy === 'USD').sort((a,b) => a.price - b.price).slice(0,5)
                let getbuyusd = action.payload.filter(x => x.side === 'SELL' && x.ccy === 'USD').sort((a,b) => b.price - a.price).slice(0,5)
                let getsellcnh = action.payload.filter(x => x.side === 'BUY' && x.ccy === 'CNH').sort((a,b) => a.price - b.price).slice(0,5)
                let getbuycnh = action.payload.filter(x => x.side === 'SELL' && x.ccy === 'CNH').sort((a,b) => b.price - a.price).slice(0,5)
                return {
                    ...state,
                    clob:{
                        usdsell: getsellusd,
                        usdbuy: getbuyusd,
                        cnhsell: getsellcnh,
                        cnhbuy: getbuycnh
                    }
                }
        default:
            return state;
    }
}