import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { store } from 'react-notifications-component';

import OrderEntry from '../components/dashboard/OrderEntry';
import QuickBuy from '../components/dashboard/QuickBuy';
import RFQBoard from '../components/dashboard/RFQBoard';
import MyRfqBoard from '../components/dashboard/MyRfqBoard';

import CLOB from '../components/dashboard/CLOB';
import NDFBoard from '../components/dashboard/NDFBoard';
import Summary from '../components/dashboard/Summary';
import Header from '../components/dashboard/Header';


import TradeSummary from '../components/dashboard/TradeSummary';
import AccountBalance from '../components/dashboard/AccountBalance';


import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import { translate } from 'react-switch-lang';
import { bindActionCreators } from 'redux'

import { getRfq, getQuote, getOrder } from '../actions/v2order';
import { getUser, getUserAccounts } from '../actions/v2user';



class Dashboard extends Component {

	ws = new WebSocket('wss://prod.api.bourse.mn/ws/v2')
	
	constructor(props) {
		super(props);
	
		this.state = {
			newrfq: false,
			newquote: false,
			newtrade: false,
		};
	  }

	componentDidMount(){
		const { user, t } = this.props;
		if(!this.props.isAuthenticated) {
			this.props.history.push('/login');
		}
		
		this.props.getRfq()
		this.props.getQuote()
		this.props.getUser()
		this.props.getOrder()
		this.props.getUserAccounts()

		this.ws.onopen = () => {
			console.log('connected')
		}
	
		this.ws.onmessage = evt => {

			const message = JSON.parse(evt.data)
			console.log(message);
			
			if(user.user_id !== message.data.userid) {
				if(message.type === "RFQ") {
					if(message.data.side === "BUY")
						this.setState({newrfq: "sell"});
					else
						this.setState({newrfq: "buy"});
					store.addNotification({
						message: t('order.newrfq'),
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
						duration: 3000,
						onScreen: true
						}
					});
					this.props.dispatch({type: 'CREATE_RFQ', payload: message.data });
				}
				
				else if (message.type === "QUOTE") {
					if(user.user_id !== message.data.quote.userid) {
						if(message.data.rfq.side === "BUY")
						this.setState({newquote: "buy"});
					else
						this.setState({newquote: "sell"});

					store.addNotification({
						message: t('order.newquote'),
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
						duration: 3000,
						onScreen: true
						}
					});
					this.props.dispatch({type: 'CREATE_QUOTE', payload: message.data });
					}
				}
				
				else if (message.type === "TRADE") {
					if(message.data.side === "BUY")
						if(user.user_id === message.data.makerid)
							this.setState({newtrade: "sell"});
						else
							this.setState({newtrade: "buy"});
					else
						if(user.user_id === message.data.makerid)
							this.setState({newtrade: "buy"});
						else
							this.setState({newtrade: "sell"});

					store.addNotification({
						message: t('order.newtrade'),
						type: "success",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: {
						duration: 3000,
						onScreen: true
						}
					});
					this.props.dispatch({type: 'CREATE_TRADE', payload: message.data });
				}

				else if (message.type === "ORDER") {
					this.props.dispatch({type: 'CREATE_ORDER', payload: message.data });
				}
			}
			
		}
	
		this.ws.onclose = () => {
			console.log('disconnected')
		}
	}

  render () {
	const { t, user } = this.props;
	return (
		<div className={this.props.theme}>
		{user.status === 'inactive' && <div className="newUser" >{t('login.active')}</div> }
		<div className="dashboard-page">
			<Header/>
			<div className="dashheight">
				<div className="row height70">
					<div className="col-lg-5 col-xl-4 order-first">
						<div className="with-title">
							<div className="section-title">{t('order.title')}</div>
							<OrderEntry/>
						</div>
					</div>
					<div className="col-lg-12 col-xl-4 order-lg-3 order-xl-2">
						<div className="with-title">
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<a className="nav-item nav-link active" id="rfq-1" onClick={() => this.setState({newrfq: 'none'})} data-toggle="tab" href="#nav-rfq-1" role="tab" aria-controls="nav-rfq-1" aria-selected="true">
                    <i className="fa fa-fw fa-comments" style={{ fontSize: '1em', marginRight: '5px', }} />
                    {t('rfq.my')} (RFQ)
										{this.state.newrfq === 'sell' ? <span className="badge" align="center"> </span> : (this.state.newrfq === 'buy' ? <span className="badge-green" align="center"> </span> : <></>)}
									</a>
									<a className="nav-item nav-link" id="rfq-2" data-toggle="tab" onClick={() => this.setState({newquote: 'none'})} href="#nav-rfq-2" role="tab" aria-controls="nav-rfq-2" aria-selected="false">
										<span className="badge-title">{t('rfq.other')}</span>
										{this.state.newquote === 'sell' ? <span className="badge" align="center"> </span> : (this.state.newquote === 'buy' ? <span className="badge-green" align="center"> </span> : <></>)}
									</a>
								</div>
							</nav>
							<div className="tab-content">
								<div className="tab-pane fade show active" id="nav-rfq-1" role="tabpanel" aria-labelledby="rfq-1">
                <RFQBoard/>
								</div>
								<div className="tab-pane fade " id="nav-rfq-2" role="tabpanel" aria-labelledby="rfq-2">
									<MyRfqBoard/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-lg-7 order-lg-2 order-xl-3">
						<div className="with-title">
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<a className="nav-item nav-link active" id="clob-usd" data-toggle="tab" href="#nav-usd" role="tab" aria-controls="nav-usd" aria-selected="true">SUU</a>
									<a className="nav-item nav-link" id="clob-cnh" data-toggle="tab" href="#nav-cnh" role="tab" aria-controls="nav-cnh" aria-selected="false">APU</a>
									<a className="nav-item nav-link" id="clob-eur" data-toggle="tab" href="#nav-eur" role="tab" aria-controls="nav-contact" aria-selected="false">GACH</a>
									{/* <a className="nav-item nav-link" id="clob-jpy" data-toggle="tab" href="#nav-jpy" role="tab" aria-controls="nav-xaxa" aria-selected="false">JPY</a> */}
									{/* <a className="nav-item nav-link" id="chart-usd" data-toggle="tab" href="#nav-chart" role="tab" aria-controls="nav-xexe" aria-selected="false"><span className="chart"/></a> */}
								</div>
							</nav>
							<div className="tab-content">
								<div className="tab-pane fade show active" id="nav-usd" role="tabpanel" aria-labelledby="clob-usd">
									<CLOB ccy="usd"/>
								</div>
								<div className="tab-pane fade " id="nav-cnh" role="tabpanel" aria-labelledby="clob-cnh">
									<CLOB ccy="cnh"/>
								</div>
								<div className="tab-pane fade " id="nav-eur" role="tabpanel" aria-labelledby="clob-eur">
									<CLOB ccy="eur"/>
								</div>
								<div className="tab-pane fade " id="nav-jpy" role="tabpanel" aria-labelledby="clob-jpy">
									<CLOB ccy="jpy"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="row">
					<div className="col-xl-2 col-lg-3 col-sm-6">
						<div className="with-title">
							<div className="section-title">USD</div>
							<QuickBuy CCY="USD"/>
						</div>
					</div>
					<div className="col-xl-2 col-lg-3 col-sm-6">
						<div className="with-title">
							<div className="section-title">CNH</div>
							<QuickBuy CCY="CNH"/>
						</div>
					</div>
					<div className="col-xl-2 col-lg-3 col-sm-6">
						<div className="with-title">
							<div className="section-title">EUR</div>
							<QuickBuy CCY="EUR"/>
						</div>
					</div>
					<div className="col-xl-2 col-lg-3 col-sm-6">
						<div className="with-title">
							<div className="section-title">JPY</div>
							<QuickBuy CCY="JPY"/>
						</div>
					</div>
					<div className="col-xl-4 col-lg-12">
						<div className="with-title">
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<a className="nav-item nav-link active" id="clob-usd" data-toggle="tab" href="#nav-ff" role="tab" aria-controls="ff" aria-selected="true">{t('order.forward')}</a>
									<a className="nav-item nav-link" id="clob-cnh" data-toggle="tab" href="#nav-nd" role="tab" aria-controls="nav-nd" aria-selected="false">NDF</a>
								</div>
							</nav>
							<div className="tab-content">
								<div className="tab-pane fade show active" id="nav-ff" role="tabpanel" aria-labelledby="clob-usd">
									<NDFBoard/>
								</div>
								<div className="tab-pane fade " id="nav-nd" role="tabpanel" aria-labelledby="clob-cnh">
									<NDFBoard/>
								</div>
							</div>
						</div>
					</div>
				</div> */}
				<div className="row height50">
					<div className="col-12">
						<div className="summary with-title">
							<nav>
								<div className="nav nav-tabs" id="nav-tab" role="tablist">
									<a className="nav-item nav-link active" id="clob-orders" data-toggle="tab" href="#nav-orders" role="tab" aria-controls="nav-orders" aria-selected="true">{t("summary.order")}</a>
									<a className="nav-item nav-link" id="clob-trades" data-toggle="tab" href="#nav-trades" onClick={() => this.setState({newtrade: 'none'})} role="tab" aria-controls="nav-trades" aria-selected="true">
										{t("summary.trade")}
										{this.state.newtrade === 'sell' ? <span className="badge" align="center"> </span> : (this.state.newtrade === 'buy' ? <span className="badge-green" align="center"> </span> : <></>)}
									</a>
									<a className="nav-item nav-link" id="clob-balance" data-toggle="tab" href="#nav-balance" role="tab" aria-controls="nav-profile" aria-selected="false">{t("summary.balance")}</a>
								</div>
							</nav>
							<div className="tab-content">
								<div className="tab-pane fade show active" id="nav-orders" role="tabpanel" aria-labelledby="clob-orders">
									<Summary/>
								</div>
								<div className="tab-pane fade" id="nav-trades" role="tabpanel" aria-labelledby="clob-trades">
									<TradeSummary/>
								</div>
								<div className="tab-pane fade" id="nav-balance" role="tabpanel" aria-labelledby="clob-balance">
									<AccountBalance/>											
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
	  	</div>
		</div>
    )
  }
}

const mapStateToProps = state => ({
	user: state.auth.user,
	user2: state.user,
	isAuthenticated: state.auth.isAuthenticated,
	theme: state.auth.theme
});

const mapDispatchToProps = dispatch => ({
	dispatch,
    ...bindActionCreators({ getRfq, getQuote, 	getUser,getOrder,getUserAccounts
	}, dispatch),
});

export default connect (mapStateToProps, mapDispatchToProps)(withRouter(translate(Dashboard)));