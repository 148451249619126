import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { createUser } from '../../actions/v2user';
import $ from "jquery";
import { store } from 'react-notifications-component';

class Bond extends Component {

  constructor(props) {
    super(props);

    this.state = {
        email:'',
        name:'',
        twoemail:'',
        prefix:'',
        custodyaccount: '',
        custodyuser: ''
    };

    this._handleChange = this._handleChange.bind(this)
    }

  newUser = () => {
    this.props.createUser(this.state);
    // store.addNotification({
    //   message: 'Хэрэглэгч нэмэгдлээ',
    //   type: "success",
    //   insert: "top",
    //   container: "top-right",
    //   animationIn: ["animated", "fadeIn"],
    //   animationOut: ["animated", "fadeOut"],
    //   dismiss: {
    //     duration: 3000,
    //     onScreen: true
    //   }
    // });
    this.setState( {twofa: false} )
    $("#addUserModal").modal("hide"); 
  }

  sendEmail = () => {

  }

  _handleChange(e) {

    let {name, value} = e.target;
    this.setState({
    [name]: value,
    });
  }

  render() {
    const users  = [];
    
    return (
      <div>
        <div className="row place-order">                    
            <button type="submit" className="btn btn-place-order-login-2" data-toggle="modal" data-target="#addUserModal">Үнэт цаас бүртгэх</button>
        </div>
      <div className="component-tab">
          <table>
          <thead>
            <tr>
              <th>Нэр</th>
              <th>Регистрийн дугаар</th>
              <th>Баталгаажуулах код / Имейл</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
          {users.map(user => {
            return (
              <tr key={user.email}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.twoemail}</td>
                {
                user.status === 'inactive' ? 
                <div className="rfq-chng">
                  <button type="button" className="btn btn-primary btn-sm btn-sell">Имэйл илгээх</button>
                </div> :
                <td>Идэвхитэй</td>
                }
              </tr>
            )}
          )}
          </tbody>
        </table>
      </div>
      <div className="modal" id="addUserModal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content-2">
                        <div className="modal-header">
                            <h5 className="modal-title">Үнэт цаас бүртгэх</h5>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                              <div className="col">
                                  <label>Үнэт цаасны нэр:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="name" className="form-control form-control-sm" id="code"  />
                              </div>
                              <div className="col">
                                  <label>Үнэт цаасны төрөл:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="email" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Үнэт цаасны тоо ширхэг:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="twoemail" className="form-control form-control-sm" id="code"  />
                              </div>
                              <div className="col">
                                  <label>Үнэ:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="prefix" className="form-control form-control-sm" id="code"  />
                              </div>
                              <div className="col">
                                  <label>Үнийн дүн:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="prefix" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Үнэт цаасны хугацаа:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyaccount" className="form-control form-control-sm" id="code"  />
                              </div>
                              <div className="col">
                                  <label>Хүү:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                              <div className="col">
                                  <label>Үнэт цаасны нөхцөл:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Татан төвлөрүүлэхээр тооцсон хөрөнгийн хэмжээ:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                              <label>Татан төвлөрүүлэх хөрөнгийн зарцуулалтын талаарх товч мэдээлэл:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                              <label>Үнэт цаасны барьцаа, батлан даалт баталгааны талаарх мэдээлэл:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                              <label>Хүлээн зөвшөөрөгдсөн байгууллагаар зээлжих эрхийн үнэлгээ, санхүүгийн чадавхын зэрэглэл тогтоолгосон бол тус мэдээллийн талаар:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                              <label>Өрийн хэрэгсэл нь даатгалын байгууллагаар даатгагдсан бол тус мэдээллийн талаар:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                              <label>Үнэт цаасыг арилжихаар төлөвлөсөн хугацаа:</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code"  />
                              </div>
                          </div>
                            
                            <div className="row">
                                <button type="button" className="btn btn-place-order-login" data-toggle="modal" onClick={this.newUser}>Бүртгэх</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.users
})
export default connect(mapStateToProps, {createUser})(Bond);