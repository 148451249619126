import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import toCurrency from '../../utils/currency'
import { cancelOrder } from '../../actions/v2order';
import { store } from 'react-notifications-component';
import { translate } from 'react-switch-lang';

class Summary extends Component {
  
  cancelOrder = (id) => {
    this.props.cancelOrder(id);
    store.addNotification({
      message: "Захиалга Цуцлагдлаа",
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
      duration: 3000,
      onScreen: true
      }
    });
  }

  convertSide = side => {
    const { t } = this.props;

    if(side === "BUY")
    return <td className="sell">{t('order.buy')}</td>
    return <td className="buy">{t('order.sell')}</td>
  }

  convertStatus = status => {
    const { t } = this.props;

    if(status === "OPEN")
    return t('summary.open')
    if(status === "CANCELLED")
    return t('summary.cancelled')
    if(status === "PARTIALLY FILLED")
    return t('summary.partfilled')
    return t('summary.filled')
  }

  render() {
    const { t } = this.props;
    const { orders, quotes, user, clobs } = this.props;
    const qt = quotes.filter(x => x.userid === user.user_id);
    console.log(qt);
    return (
        <div className="component-tab">
          <table>
          <thead>
            <tr>
              <th>{t('order.ccy')}</th>
              <th>{t('order.instrument')}</th>
              <th>{t('order.side')}</th>
              <th>{t('order.price')}</th>
              <th>{t('order.amount')}</th>
              <th>{t('order.valuedate')}</th>
              <th>{t('summary.status')}</th>
              <th>{t('order.cancel')}</th>
            </tr>
          </thead>
          <tbody>
          {/* {orders.concat(qt).concat(clobs).sort((a, b) => a.date - b.date).map(order => {
            return (
              <tr key={order.id}>
                <td>{order.ccy}</td>
                <td>{t('order.spot')}</td>
                {this.convertSide(order.side)}
                <td>{toCurrency(order.price)}</td>
                <td>{toCurrency(parseFloat(order.size) - parseFloat(order.filled)) + '(' + toCurrency(order.size) + ')'}</td>
                <td>{order.valuedate.slice(0, 10)}</td>
                <td>{this.convertStatus(order.status)}</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled={order.status !== "OPEN" && order.status !== "PARTIALLY FILLED"} className="btn btn-primary btn-sm btn-buy"  onClick={() => this.cancelOrder(order.id)}>{t('order.cancel')}</button>
                  </div>
                </td>

              </tr>
            )}
          )} */}

              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
              <tr key={2}>
                <td>SUU-MN-3028037833100</td>
                <td>Хоёрдогч</td>
                <td>Авах</td>
                <td>18</td>
                <td>100,000,000</td>
                <td>2021-05-17</td>
                <td>Нээлттэй</td>
                <td>
                  <div className="rfq-chng">
                    <button type="button" disabled className="btn btn-primary btn-sm btn-buy">{t('order.cancel')}</button>
                  </div>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.user.orders,
  quotes: state.user.quotes,
  clobs: state.user.clobs,
  user: state.auth.user,
})
export default connect(mapStateToProps, {cancelOrder})(translate(Summary));