import { GET_ADMIN_USERS, CREATE_USER } from "../actions/action_types";

const initialState = {
    users:[{}]
}

export default function(state = initialState, action){
    switch(action.type){
        case GET_ADMIN_USERS:
            return{
                ...state, users: action.payload
            }
        case CREATE_USER:
            return {
                ...state, users: [...state.users, action.payload]
            };
        default:
            return state;
    }
}