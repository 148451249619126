import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginUser } from '../actions/authentication';
import { withLocalize } from "react-localize-redux";
import Header from '../components/dashboard/Header';
import { translate } from 'react-switch-lang';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const user = {
            email: this.state.email,
            password: this.state.password,
        }
        this.props.loginUser(user);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if(nextProps.auth.isAuthenticated){
            this.props.history.push('/');
        }
    }

    componentDidMount(){
		if(this.props.auth.isAuthenticated) {
			this.props.history.push('/');
		}
	}

    render() {
        const { t } = this.props;
        const error = this.props.auth.error ? 
        <div className="row">
            <p className="error">{t('login.error')}</p>
        </div> 
        : <div className="row">
            <p className="error">&nbsp;</p>
        </div> ;
        const logo = this.props.theme === 'light-theme' ? "/images/logo.png" : "/images/main-logo.png";
        return(
        <div className={this.props.theme}>
        <div>
        <Header/> 
        <div className="width100 row justify-content-center black-background" >
        <div className="col-xl-4 col-md-8 col-sm-10 sm-padding">
        <div className="with-title login-resp">
            <div className="component loginForm">
                <form onSubmit={ this.handleSubmit } className="buy-sell-form white-form loginFFORM">
                    <div className="form-group">
                        <div className="row">
                            <img src={logo} alt="Logo" className="loginlogo center-block" />
                        </div>
                        {error}
                        <div className="row">
                            <div className="col">
                                <label>{t('login.email')}</label>
                                <input type="text" autoComplete="off" name="email"
                    onChange={ this.handleInputChange }
                    value={ this.state.email } className="form-control form-control-sm" id="Price"  />
                                <label>{t('login.password')}</label>
                                <input type="password" autoComplete="off" name="password"
                    onChange={ this.handleInputChange }
                    value={ this.state.password } className="form-control form-control-sm" id="Price2" />
                            </div>
                        </div>
                        <div className="row place-order">                    
                            <button type="submit" className="btn btn-place-order-login" data-toggle="modal" data-target="#myModal">{t('login.login')}</button>
                        </div>
                        <hr/>
                        <div className="row">
                            <a className="register" href="mailto:contact@bourse.mn">{t('login.reset')}</a>
                        </div>
                        <div className="row">
                            <a className="register" href="https://bourse.mn">{t('login.register')}</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    theme: state.auth.theme
})

export  default connect(mapStateToProps, { loginUser })(withLocalize(translate(Login)))