import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux';
import store from './store/store';
import { LocalizeProvider } from "react-localize-redux";

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import 'bootstrap/dist/css/bootstrap.min.css';

import Login from './containers/Login';
import Dashboard from './containers/Dashboard'
import AdminDashboard from './containers/AdminDashboard'
import setAuthToken from './setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authentication';
import jwt_decode from 'jwt-decode';
import Profile from './containers/Profile';
import en from './translations/en.json';
import mon from './translations/mon.json';

import {
  setTranslations,
  setDefaultLanguage,
  setLanguage
} from 'react-switch-lang';
import ProfileDashboard from './containers/ProfileDashboard';

setTranslations({ en, mon });
setDefaultLanguage('en');
var history = createBrowserHistory();

if(localStorage.language){
  setLanguage(localStorage.language);
}

if(localStorage.jwtToken){
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded))
  
  const currentTime = Date.now() / 1000;
  if(decoded.exp < currentTime){
    store.dispatch(logoutUser(history));
    window.location.href = '/login'
  }
}

const AppRouter = () => (
  <>
  <ReactNotification />
  <Provider store = { store }>
    <LocalizeProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/profile" component={ProfileDashboard} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/admin" component={AdminDashboard} />
          <Route component={SampleComponent} />
        </Switch>
      </Router>
    </LocalizeProvider>
  </Provider>
  </>
);

export default AppRouter;

class SampleComponent extends Component {
  render() {
    return (
      <div/>
    )
  }
}
