import axios from 'axios';
import { GET_ADMIN_USERS } from './action_types';

export const getAllUser = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/admin/users')
    .then(res => {
        dispatch(GetAllUsers(res.data.message));
    })
    .catch(err => {
        console.log(err);
    });
}

export const GetAllUsers = user => {
    return {
        type: GET_ADMIN_USERS,
        payload: user
    }
}