import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { createUser } from '../../actions/v2user';
import $ from "jquery";
import { store } from 'react-notifications-component';

class Users extends Component {

  constructor(props) {
    super(props);

    this.state = {
        email:'',
        name:'',
        twoemail:'',
        prefix:'',
        custodyaccount: '',
        custodyuser: ''
    };

    this._handleChange = this._handleChange.bind(this)
    }

  newUser = () => {
    this.props.createUser(this.state);
    store.addNotification({
      message: 'Хэрэглэгч нэмэгдлээ',
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true
      }
    });
    this.setState( {twofa: false} )
    $("#addUserModal").modal("hide"); 
  }

  sendEmail = () => {

  }

  _handleChange(e) {

    let {name, value} = e.target;
    this.setState({
    [name]: value,
    });
  }

  render() {
    const { users } = this.props;
    
    return (
      <div>
        <div className="row place-order">                    
            <button type="submit" className="btn btn-place-order-login-2" data-toggle="modal" data-target="#addUserModal">Шинэ харилцагч бүртгэх</button>
        </div>
      <div className="component-tab">
          <table>
          <thead>
            <tr>
              <th>Нэр</th>
              <th>Имейл</th>
              <th>Баталгаажуулах код / Имейл</th>
              <th>Статус</th>
            </tr>
          </thead>
          <tbody>
          {users.map(user => {
            return (
              <tr key={user.email}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.twoemail}</td>
                {
                user.status === 'inactive' ? 
                <div className="rfq-chng">
                  <button type="button" className="btn btn-primary btn-sm btn-sell">Имэйл илгээх</button>
                </div> :
                <td>Идэвхитэй</td>
                }
              </tr>
            )}
          )}
          </tbody>
        </table>
      </div>
      <div className="modal" id="addUserModal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Шинэ харилцагч бүртгэх</h5>
                        </div>
                        <div className="modal-body">
                          <div className="row">
                              <div className="col">
                                  <label>Байгууллагын нэр</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="name" className="form-control form-control-sm" id="code" placeholder="Нэр" />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Имейл</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="email" className="form-control form-control-sm" id="code" placeholder="Имейл" />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Баталгаажуулах Имейл</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="twoemail" className="form-control form-control-sm" id="code" placeholder="Баталгаажуулах Имейл" />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Богино нэр</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="prefix" className="form-control form-control-sm" id="code" placeholder="Богино нэр" />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Кастодиан данс</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyaccount" className="form-control form-control-sm" id="code" placeholder="Кастодиан данс" />
                              </div>
                          </div>
                          <div className="row">
                              <div className="col">
                                  <label>Кастодиан хэрэглэгч</label>
                                  <input onChange={this._handleChange} type="text" autoComplete="off"  name="custodyuser" className="form-control form-control-sm" id="code" placeholder="Кастодиан хэрэглэгч" />
                              </div>
                          </div>
                            <div className="row">
                              <div className="col">
                                <label> Төрөл </label>
                                <select className="custom-select form-control-sm" id="commitment" name="commitment">
                                    <option  value="soft">Дилер</option>
                                    <option  value="softer">Трейдр</option>
                                    <option value="firm">ААН</option>
                                    <option value="firm">Иргэд</option>
                                </select>
                              </div>
                            </div>
                            <div className="row">
                                <button type="button" className="btn btn-place-order-login" data-toggle="modal" onClick={this.newUser}>Шинээр харилцагч бүртгэх</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.admin.users
})
export default connect(mapStateToProps, {createUser})(Users);