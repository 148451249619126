import { combineReducers } from 'redux';
import userReducer from './userReducer';
import rfqReducer from './rfqReducer';
import clobreducer from './clobReducer';
import authReducer from './authReducer';
import adminReducer from './adminReducer';

export default combineReducers({
    user: userReducer,
    auth: authReducer,
    rfq: rfqReducer,
    clob: clobreducer,
    admin: adminReducer
});