import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { createRfq, createOrder } from '../../actions/v2order';
import { send2FA } from '../../actions/order';
import { store } from 'react-notifications-component';
import NumberFormat from 'react-number-format';
import { translate } from 'react-switch-lang';
import $ from "jquery";

class OrderEntry extends Component {
    constructor(props) {
    super(props);

    this.state = {
        ccy:'USD',
        instrument:'SPOT',
        side:'BUY',
        type:'LIMIT',
        price: 0,
        size: 0,
        minq: 0,
        oduration:'GTD',
        valuedate: new Date().toISOString(),
        expiredate: new Date().toISOString(),
        commitment:'soft',
        categories:'0',
        status:'0',
        responseid: '0',
        market:false,
        twofa: false,
        code: "",
        notenough: false,
        pvp: true
    };

    this._handleChange = this._handleChange.bind(this)
    }

    toCurrency = (value) => {
        return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'); 
    }

    placeorder = () => {
        console.log(this.props);
        const {ccy, side, price, size, valuedate, commitment} = this.state; 
        const { t } = this.props;

        var item = {  
            ccy: ccy,
            side: side,
            price: price.toString(),
            size: size.toString(),
            valuedate: valuedate,
        };
        if( this.state.commitment === 'soft') {
            this.props.createRfq(item)
            store.addNotification({
                message: t('order.rfqsuccess'),
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
            this.setState( {twofa: false} )
            $("#myModal").modal("hide");
        }
        else {
            if( !this.state.twofa ) {
                this.props.send2FA(item);
                store.addNotification({
                    message: t('order.emailcode'),
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                    duration: 3000,
                    onScreen: true
                    }
                });
                this.setState( {twofa: true} )
            }
    
            else {
                if(this.state.code === this.props.code){
                    this.props.createOrder(item)
                    store.addNotification({
                        message: t('order.ordersuccess'),
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
                    this.setState( {twofa: false} )
                    $("#myModal").modal("hide");
                }
                else{
                    store.addNotification({
                        message: t('order.wrongcode'),
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                        duration: 3000,
                        onScreen: true
                        }
                    });
                }
            }
        }
    }

    _handleChange(e) {

        let {name, value} = e.target;
        this.setState({
        [name]: value,
        });

        if(name === "type")
            if(value === "MARKET")
                this.setState( {market: true} )
            else
                this.setState( {market: false} )
    }

    _handleMinq = (e) => {
        if(e.floatValue > this.state.size)
            this.setState( { minq: this.state.minq } )
        else
            this.setState( { minq: e.floatValue } )
    }

    _handlePrice = (e) => {
        this.setState( {price: e.floatValue } )
    }

    _handleSize = (e) => {
        this.setState( {size: e.floatValue } )
    }

    onAddressChanged = (e) => {
        this.setState( { pvp: !this.state.pvp } );
    }

  render() {
    
    const { t, user_id } = this.props;
    console.log(user_id);
    const twofa = this.state.twofa ? 
    <div className="row justify-content-center">
        <div className="col-md-5">
            <label>{t('order.twofactor')}</label>
            <input 
            type="text" 
            onChange={this._handleChange} 
            autoComplete="off"  
            name="code" 
            className="form-control form-control-sm" 
            id="code" 
            placeholder={t('rfq.email123')} />
        </div>
    </div>: <div/>;
    const ccyAccount = this.state.side === "BUY" ? this.state.ccy : 'MNT';
    return (
        <div className="component">
            <form className="buy-sell-form white-form">
                <div className="form-group">
                    <div className="row">
                        <div className="col">
                            <label>(1) {t('order.ccy')}</label>
                            <select onChange={this._handleChange} className="custom-select form-control-sm" id="ccy" name="ccy">
                                <option value="SUU-MN-3028037833100">SUU-MN-3028037833100</option>
                                <option value="APU-MN-1030000033311">APU-MN-1030000033311</option>
                                <option value="GACH-MN-2103000003133">GACH-MN-2103000003133</option>
                            </select>
                        </div>
                        <div className="col">
                            <label>(2) {t('order.instrument')}</label>
                            <select onChange={this._handleChange} className="custom-select form-control-sm" id="instrument" name="instrument">
                                <option  value="SPOT">{t('order.spot')}</option>
                                <option value="FWD">{t('order.forward')}</option>
                                {/* <option value="NDF">{t('order.ndf')}</option> */}
                            </select>
                        </div>
                        <div className="col">
                            <label>(3) {t('order.side')}</label>
                            <select onChange={this._handleChange} className="custom-select form-control-sm" id="side" name="side">
                                <option  value="BUY">{t('order.buy')}</option>
                                <option value="SELL">{t('order.sell')}</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>(4) {t('order.commitment')}</label>
                            <select onChange={this._handleChange} className="custom-select form-control-sm" id="commitment" name="commitment">
                                <option  value="soft">{t('order.soft')}</option>
                                <option value="firm">{t('order.firm')}</option>
                            </select>
                        </div>
                        <div className="col">
                            <label>(5) {t('order.price')}</label>
                            <NumberFormat thousandSeparator={true}
                            allowLeadingZeros={false}
                            decimalScale={2}
                            value={this.state.price}
                            onValueChange={this._handlePrice} 
                            name="price"
                            placeholder="0"
                            id="price"
                            className="form-control form-control-sm" 
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>(6) {t('order.amount')}</label>
                            <NumberFormat thousandSeparator={true}
                            decimalScale={2}
                            value={this.state.size}
                            onValueChange={this._handleSize} 
                            name="size"
                            placeholder="10,000"
                            id="size"
                            className="form-control form-control-sm" 
                            />
                        </div>
                        <div className="col">
                            <label>(7) {t('order.minq')}</label>
                            <NumberFormat thousandSeparator={true}
                            decimalScale={2}
                            value={this.state.minq}
                            onValueChange={this._handleMinq} 
                            name="minq"
                            placeholder="5,000"
                            id="price"
                            className="form-control form-control-sm" 
                            />
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col">
                            <label>(8) {t('order.duration')}</label>
                            <select onChange={this._handleChange} className="custom-select form-control-sm" id="od" name="oduration">
                                <option value="GTC">{t('order.gtc')}</option>
                                <option value="GTD">{t('order.gtd')}</option>
                            </select>
                        </div>
                        <div className="col">
                            <label>(9) {t('order.valuedate')}</label>
                            <input type="date" 
                            required="required" 
                            onChange={this._handleChange} 
                            name="valuedate" 
                            defaultValue={this.state.valuedate.slice(0, 10)} 
                            disabled={this.state.instrument === 'SPOT' ? true : false} 
                            className="form-control form-control-sm" 
                            id="Price" />
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-7">
                        <label>(10) {t('order.counterparties')}</label><br/>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" id="checkbox1" type="checkbox" value="1" />
                                <span className="checkmark"></span>
                                <label htmlFor="checkbox1" className="form-check-label">{t('order.dealer')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="checkbox2" value="2" />
                                <span className="checkmark"></span>
                                <label htmlFor="checkbox2" className="form-check-label">{t('order.trader')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="checkbox3" value="3" />
                                <span className="checkmark"></span>
                                <label htmlFor="checkbox3" className="form-check-label">{t('order.corporate')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="checkbox4" value="4" />
                                <span className="checkmark"></span>
                                <label htmlFor="checkbox4" className="form-check-label">{t('order.retail')}</label>
                            </div>
                        </div> */}
                        { user_id !== '502bcd05-9bc7-4483-937e-11d538013f8e' &&

                        <div className="col-5">
                         <label>(11) {t('order.paymenttype')}</label> <br/>
                            <div className="form-check form-check-inline hoverText1">
                                <input className="form-check-input" name="payment" id="checkbox6" type="radio" value="1"  onChange={this.onAddressChanged}/>
                                <span className="checkmark"></span>
                                <label htmlFor="checkbox1" className="form-check-label"  >{t('order.pvs')} </label>
                                <span className="tooltiptext1">{t('order.pvsdetail')}</span>
                            </div>
                            <div className="form-check form-check-inline hoverText">
                                <input className="form-check-input" name="payment" type="radio" id="checkbox7" value="2" onChange={this.onAddressChanged} />
                                <span className="checkmark"></span>
                                <label htmlFor="checkbox1" className="form-check-label "  >{t('order.pvp')}</label>
                                <span className="tooltiptext">{t('order.pvpdetail')}</span>
                            </div>
                        </div> }
                        <div className="col">
                        <label>(12) {t('order.lasttime')}</label>
                            <select onChange={this._handleChange} className="custom-select form-control-sm" id="od" name="oduration">
                                <option value="15">15 {t('order.minutes')}</option>
                                <option value="30">30 {t('order.minutes')}</option>
                                <option value="45">45 {t('order.minutes')}</option>
                                <option value="60">60 {t('order.minutes')}</option>
                        </select>
                        </div> 
                    </div>
                     {/* { user_id !== '502bcd05-9bc7-4483-937e-11d538013f8e' && */}
                    {/* //  <div className="row"> */}
                        
                        {/* <div className="col">
                            <label>(13) {t('order.pvsamount')}</label>
                            <input type="number" 
                            onChange={this._handleChange} 
                            name="available" 
                            defaultValue={0} 
                            disabled 
                            className="form-control form-control-sm" 
                            id="Price" />
                        </div> */}
                    {/* </div> } */}
                    <div className="row place-order" style={{marginTop: '50px'}}>                    
                        <button type="button" className="btn btn-place-order" data-backdrop="static" data-keyboard="false" data-toggle="modal" data-target="#myModal">{t('order.send')}</button>
                    </div>
                </div>
            </form>
            
            <div className="modal" id="myModal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{(this.state.side === "BUY") ? t('order.buy') : t('order.sell')} {t('order.order')}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-5 confirm-main">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.ccy')}</p>
                                        <p className="value-text">{this.state.ccy}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.amount')}</p>
                                        <p className="value-text">{this.toCurrency(this.state.size)}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.price')}</p>
                                        <p className="value-text">{this.toCurrency(this.state.price)}</p>
                                    </div>
                                </div>
                                <div className="col-5 confirm-sub">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.instrument')}</p>
                                        <p className="value-text">{(this.state.instrument === "SPOT") ? t('order.spot') : "NDF"} </p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.valuedate')}</p>
                                        <p className="value-text">{this.state.valuedate.slice(0, 10)}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.account')}</p>
                                        <select className="custom-select form-control-sm" id="od" name="oduration">
                                            {
  this.props.accounts.filter(x => x.Ccy === ccyAccount).map( x =>  <option key={x.AccountNumber} value={x.Ccy}>{x.BankName + " - " + x.Ccy + " - " + x.AccountNumber}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {twofa}
                            <div className="row">
                                <button type="button" className="btn btn-place-order-cancel" data-toggle="modal" data-target="#myModal" onClick={() => this.setState({twofa: false})}>{t('order.cancel')}</button>
                                <button type="button" disabled={this.props.accounts.filter(x => x.Ccy === ccyAccount).length === 0} className="btn btn-place-order-confirm" data-toggle="modal" onClick={this.placeorder}>{t('order.confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
    balance: state.user.balance,
    code: state.auth.code,
    accounts: state.user.accounts,
    user_id: state.auth.user.user_id
})
export default connect(mapStateToProps, {createRfq, send2FA, createOrder})(translate(OrderEntry));