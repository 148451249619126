import { SET_CURRENT_USER, ERROR_USER, TWO_FACTOR, CHANGE_THEME, CHANGE_STATUS } from "../actions/action_types";
import isEmpty from '../is-empty'

const initialState = {
    isAuthenticated: false,
    error: false,
    user:{ status: localStorage.hasOwnProperty('status') ? 'active' : 'inactive' },
    code: '',
    theme: localStorage.theme || 'black-theme'
}

export default function(state = initialState, action){
    switch(action.type){
        case CHANGE_THEME:
            localStorage.setItem('theme', action.payload)
            return{
                ...state, theme: action.payload
            }
        case TWO_FACTOR:
            return{
                ...state, code: action.payload.message
            }
        case SET_CURRENT_USER:
            console.log(action.payload);
            if(action.payload.status === 'active')  { 
                localStorage.setItem('status', 'active');
                return{
                    ...state, isAuthenticated: !isEmpty(action.payload), error: false,
                    user:{ ...state.user, exp: action.payload.exp, user_id: action.payload.user_id, status: 'active'}
                }
            }
        return{
            ...state, isAuthenticated: !isEmpty(action.payload), error: false,
            user:{ ...state.user, exp: action.payload.exp, user_id: action.payload.user_id }
        }
        case CHANGE_STATUS:
            localStorage.setItem('status', 'active');
        return{
            ...state, user: { ...state.user, status: 'active' }
        }
        case ERROR_USER:
        return{
            ...state, isAuthenticated: false, error: true,
        }
        default:
            return state;
    }
}