import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import { translate } from 'react-switch-lang';
import { send2FA } from '../../actions/order';
import { store } from 'react-notifications-component';
import $ from "jquery";
import toCurrency from '../../utils/currency'

class QuickBuy extends Component {
  constructor(props) {
    super(props);
    this.state = {
        size: 0,
        sell: 0,
        buy: 0,
        twofa: false,
        code: "",
        side: "",
        type: "FOK"
    };
    this._handleChange = this._handleChange.bind(this)
  }

  buysell = () => {
  }

  _handleChange(e) {

    let {name, value} = e.target;
    this.setState({
    [name]: value,
    });

    if(name === 'type') {
        this.calculateUsdSell( parseFloat(e.target.value) );
        this.calculateUsdBuy( parseFloat(e.target.value) );
        if(this.state.type === "FOK" && value === "FAK"){
        }
    }
}

  handleChange = (e) => {
      this.calculateUsdSell( parseFloat(e.target.value) );
      this.calculateUsdBuy( parseFloat(e.target.value) );
      this.setState( {size: parseFloat(e.target.value ) } );

  }

  componentDidMount() {
  }

  placeorder = () => {
    console.log(this.props);
    const {ccy, side, size} = this.state; 
    const { t } = this.props;

    var item = {  
        ccy: this.props.CCY,
        side: side,
        price: side === 'BUY' ? this.state.buy.toString() : this.state.sell.toString(),
        size: size.toString(),
    };

    if( !this.state.twofa ) {
        this.props.send2FA(item);
        store.addNotification({
            message: t('order.emailcode'),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
            duration: 3000,
            onScreen: true
            }
        });
        this.setState( {twofa: true} )
    }

    else {
        if(this.state.code === this.props.code){
            store.addNotification({
                message: t('order.ordersuccess'),
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
            $("#quickBuyModal"+this.props.CCY).modal("hide");
        }
        else{
            store.addNotification({
                message: t('order.wrongcode'),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });
        }
    }
}

  calculateUsdSell = (amount) => {
      let sum = 0;
      let size = amount;
      let total = 0;
      const { usdsell } = this.props;
      const array = usdsell.filter(x => x.userid !== this.props.auth.user.user_id).slice().reverse()
      for(let element of array){
        total  = total + parseFloat(element.size);
        if(size > parseFloat(element.size)){
            size = size - element.size;
            sum += element.size*element.price;
        }
        else{
            sum += size * element.price;
            break;
        }
      }

      if(total < amount) {
        if(this.state.type === "FAK" && total > 0)
            this.setState({sell: Math.ceil((sum/total)*100) / 100 - 0.10, size: total})
        else 
            this.setState({sell: 0})
      }
      else
        this.setState({sell: Math.ceil((sum/amount)*100) / 100 - 0.10})
  }

  calculateUsdBuy = (amount) => {
    let sum = 0;
    let size = amount;
    let total = 0;
    const { usdbuy } = this.props;
    const array = usdbuy.filter(x => x.userid !== this.props.auth.user.user_id).slice().reverse()
    for(let element of array){
        total  = total + parseFloat(element.size);
      if(size > parseFloat(element.size)){
          size = size - element.size;
          sum += element.size*element.price;
      }
      else{
          sum += size * element.price;
          break;
      }
    }
    if(total < amount) {
        if(this.state.type === "FAK" && total > 0)
            this.setState({buy: Math.ceil((sum/total)*100) / 100 + 0.10, size: total})
        else 
            this.setState({buy: 0})
    }
    else
        this.setState({buy: Math.ceil((sum/amount)*100) / 100 + 0.10})
}

  formatPrice = (price) => {
    if(price === 'USD'){
        let decimal = this.state.sell.toFixed(2).toString().split('.');
        if(this.state.sell === 0 || isNaN(this.state.sell))
            return (<> <small>--</small><p className="price">--<i>__</i></p> </>)
        return (<> <small>{Math.floor(this.state.sell / 100)}</small><p className="price">{(Math.floor(this.state.sell) % 100).toString().padStart(2,'0')}<i>{'.' + decimal[decimal.length - 1]}</i></p> </>)
    }
    if(price === 'USD1'){
        let decimal = this.state.buy.toFixed(2).toString().split('.');
        if(this.state.buy === 0 || isNaN(this.state.buy))
            return (<> <small>--</small><p className="price">--<i>__</i></p> </>)
        return (<> <small>{Math.floor(this.state.buy / 100)}</small><p className="price">{(Math.floor(this.state.buy) % 100).toString().padStart(2,'0')}<i>{'.' + decimal[decimal.length - 1]}</i></p> </>)

    }
    if(price === 'CNH')
        return (<> <small>--</small><p className="price">--<i>__</i></p> </>)
    if(price === 'CNH1')
        return (<> <small>--</small><p className="price">--<i>__</i></p> </>)

    return (<> <small>--</small><p className="price">--<i>__</i></p> </>)

  }

  render() {
        const { t } = this.props;
      const ccy = this.props.CCY;
      const ccyAccount = this.state.side === "BUY" ? this.props.CCY : 'MNT';
      const twofa = this.state.twofa ? 
      <div className="row justify-content-center">
          <div className="col-md-5">
              <label>{t('order.twofactor')}</label>
              <input 
              type="text" 
              onChange={this._handleChange}
              autoComplete="off"  
              name="code" 
              className="form-control form-control-sm" 
              id="code" 
              placeholder={t('order.twofactor')} />
          </div>
      </div>: <div/>;
      var price;
      if (ccy === 'USD') {
        price = <div className="row">
                        <div className="col buysell-count buy"  data-toggle={this.state.size === 0 || this.state.sell === 0 || isNaN(this.state.sell) ? 'none' : 'modal'} onClick={() => {
                            if(this.state.size === 0 || this.state.sell === 0 || isNaN(this.state.sell))
                                console.log('disabled');
                            else
                                this.setState({side: 'SELL'}) 
                        }} data-target={"#quickBuyModal"+this.props.CCY}>
                            {this.formatPrice(this.props.CCY)}
                        </div>
                        <div className="col buysell-count nonbor sell" data-toggle={this.state.size === 0 || this.state.buy === 0 || isNaN(this.state.buy) ? 'none' : 'modal'} onClick={() => {
                            if(this.state.size === 0 || this.state.buy === 0 || isNaN(this.state.buy))
                                console.log('disabled');
                            else
                                this.setState({side: 'BUY'}) 
                        }}  data-target={"#quickBuyModal"+this.props.CCY}>
                            {this.formatPrice("USD1")}
                        </div>
                    </div>;
      } else if(ccy ==='CNH') {
                    price = <div className="row">
                    <div className="col buysell-count buy" data-target={'#'+this.props.CCY} onClick={this.buysell}>
                        {this.formatPrice("CNH")}
                    </div>
                    <div className="col buysell-count nonbor sell"  data-target={'#'+this.props.CCY} onClick={this.buysell}>
                        {this.formatPrice("CNH1")}
                    </div>
                </div>;
                    }else if(ccy === 'EUR') {
                        price = <div className="row">
                        <div className="col buysell-count buy"  data-target={'#'+this.props.CCY} onClick={this.buysell}>
                            {this.formatPrice(1)}
                        </div>
                        <div className="col buysell-count nonbor sell"  data-target={'#'+this.props.CCY} onClick={this.buysell}>
                            {this.formatPrice(1)}
                        </div>
                    </div>;
                }
                else {
                    price = <div className="row">
                    <div className="col buysell-count buy"  data-target={'#'+this.props.CCY} onClick={this.buysell}>
                        {this.formatPrice(1)}
                    </div>
                    <div className="col buysell-count nonbor sell" data-target={'#'+this.props.CCY} onClick={this.buysell}>
                        {this.formatPrice(1)}
                    </div>
                </div>;
                }
    return (
        <div className="component">
            <form className="buy-sell-form white-form">
                <div className="form-group">
                    <div className="row">
                        <div className="col col-md-7">
                            <input type="number" className="form-control form-control-sm" id="Price" placeholder="1000" value={this.state.size} onChange={this.handleChange} />
                        </div>
                        <div className="col">
                            <select className="custom-select form-control-sm" id="type" name="type" onChange={this._handleChange} >
                                <option value="FOK">FOK</option>
                                <option  value="FAK">FAK</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    {/* <select className="custom-select form-control-sm" id="date" name="date">
                                <option  value="1">06/08/2018 (SPOT)</option>
                                <option value="2">07/07/2018 (NDF)</option>
                                <option value="3">08/07/2018 (FORWARD)</option>
                                <option value="3">09/07/2018 (NDF)</option>
                                <option value="3">10/07/2018 (NDF)</option>
                    </select> */}
                </div>
                <div className="form-group">
                    <div className="row">
                        <div className="col buysell-title">
                        <label className="sell" htmlFor="Quantity">{this.props.CCY} {t('order.sell')}</label>
                        </div>
                        <div className="col buysell-title">
                            <label className="buy" htmlFor="Quantity">{this.props.CCY} {t('order.buy')}</label>
                        </div>
                    </div>
                    {price}
                </div>
            </form>
            <div className="modal" id={"quickBuyModal"+this.props.CCY}>
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{(this.state.side === "BUY") ? t('order.buy') : t('order.sell')} {t('order.order')}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-5 confirm-main">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.ccy')}</p>
                <p className="value-text">{this.props.CCY}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.amount')}</p>
                <p className="value-text">{toCurrency(this.state.size)}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.price')}</p>
                                        <p className="value-text">{this.state.side === 'BUY' ? toCurrency(this.state.buy) : toCurrency(this.state.sell) }</p>
                                    </div>
                                </div>
                                <div className="col-5 confirm-sub">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.instrument')}</p>
                                        <p className="value-text">{t('order.spot')}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.account')}</p>
                                        <select className="custom-select form-control-sm" id="od" name="oduration">
                                            {
  this.props.accounts.filter(x => x.Ccy === ccyAccount).map( x =>  <option key={x.AccountNumber} value={x.Ccy}>{x.BankName + " - " + x.Ccy + " - " + x.AccountNumber}</option>)
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {twofa}
                            <div className="row">
                                <button type="button" className="btn btn-place-order-cancel" data-toggle="modal" data-target={"#quickBuyModal"+this.props.CCY}>{t('order.cancel')}</button>
                                <button type="button" disabled={this.props.accounts.filter(x => x.Ccy === ccyAccount).length === 0} className="btn btn-place-order-confirm" data-toggle="modal" onClick={this.placeorder}>{t('order.confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    usdsell: state.clob.clob.usdsell,
    usdbuy: state.clob.clob.usdbuy,
    clobcnh1: state.rfq.clobcnh1,
    clobcnh2: state.rfq.clobcnh2,
    clobeur1: state.rfq.clobeur1,
    clobeur2: state.rfq.clobeur2,
    clobjpy1: state.rfq.clobjpy1,
    clobjpy2: state.rfq.clobjpy2,
    accounts: state.user.accounts,
    code: state.auth.code,
  })
export default connect(mapStateToProps, {send2FA})(translate(QuickBuy));