import React, { Component } from 'react'; //eslint-disable-line no-unused-vars
import { connect } from 'react-redux';
import toCurrency from '../../utils/currency'
import { translate } from 'react-switch-lang';
import { createQuote } from '../../actions/v2order';
import { send2FA } from '../../actions/order';
import $ from "jquery";
import { store } from 'react-notifications-component';

class RFQBoard extends Component {

  constructor(props) {
    super(props);

    this.state = {
        twofa: false,
        code: "",
        price: '0',
        size: '0',
        rfqid: '',
        currentRfq: {}
    };
    this._handleChange = this._handleChange.bind(this)
  }

  _handleChange(e) {
    let {name, value} = e.target;
    this.setState({
    [name]: value,
    });
  }

  // componentDidMount = () => {
  //   $("#quoteModal").modal("show"); 
  // }

  placeorder = () => {
    var item = {  
        rfqid: this.state.rfqid,
        price: this.state.price,
        size: this.state.size,
        ccy: this.state.currentRfq.ccy,
        side: this.state.currentRfq.side === 'BUY' ? 'SELL' : 'BUY'
    };
    const { t } = this.props;


    if( !this.state.twofa ) {
        this.props.send2FA(item);
        store.addNotification({
            message: t('order.emailcode'),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
            duration: 3000,
            onScreen: true
            }
        });
        this.setState( {twofa: true} )
    }

    else {
        if(this.state.code === this.props.code){
          console.log(item);
            this.props.createQuote(item);
            store.addNotification({
              message: t('order.quotesent'),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            this.setState( {twofa: false} )
            $("#quoteModal").modal("hide"); 
        }
        else{
            store.addNotification({
                message: t('order.wrongcode'),
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                duration: 3000,
                onScreen: true
                }
            });

        }
    }
  }

  convertSide = side => {
    const { t } = this.props;
    if(side === "BUY")
    return <td className="buy">{t('order.sell')}</td>
  return <td className="sell">{t('order.buy')}</td>
  }

  convertPrice = (side, price, ccy ) => {
    const commision = ccy === 'USD' ? 0.25 : 0.10;
    if(parseFloat(price) > 0) {
      if(side === 'SELL')
       return <td>{toCurrency(parseFloat(price) + commision)}</td>
      return <td>{toCurrency(parseFloat(price) - commision)}</td>
    }
    return <td>{toCurrency(price)}</td>
  }

  render() {
    const { rfq, quote, user } = this.props;
    let filter;
    console.log(user.user_id);
    console.log(rfq);
    
    if(user.user_id === '502bcd05-9bc7-4483-937e-11d538013f8e')
      filter = rfq.filter(x => x.userid !== user.user_id && x.userd === '1')
    else
      filter = rfq.filter(x => x.userid !== user.user_id)
    const quotedrfqs = filter.map(r => {
      let array = quote.filter(x => x.rfqid === r.id && x.status === "OPEN" && x.userid === user.user_id)
      const pair = {quotes: array}
      r = {...r, ...pair}
      return r
    });
	  const { t } = this.props;
    const twofa = this.state.twofa ? 
    <div className="row justify-content-center">
        <div className="col-md-5">
            <label>{t('order.twofactor')}</label>
            <input 
            type="text" 
            onChange={this._handleChange} 
            autoComplete="off"  
            name="code" 
            className="form-control form-control-sm" 
            id="code" 
            placeholder={t('rfq.email123')} />
        </div>
    </div>: <div/>;
		return (
      <div className="component">
        <table className="rfq-table">
          <thead>
            <tr>
              <th>{t('order.ccy')}</th>
              <th>{t('order.instrument')}</th>
              <th>{t('order.side')}</th>
              <th>{t('order.price')}</th>
              <th style={{width: '10%'}}>{t('order.amount')}</th>
              <th>{t('order.valuedate')}</th>
              <th>{t('rfq.response')}</th>
            </tr>
          </thead>
          <tbody>
          {/* {quotedrfqs.map(rfq => {
            return (
              <>
                <tr key={rfq.id}>
                  <td>{rfq.ccy}</td>
                  <td>{t('order.spot')}</td>
                  {this.convertSide(rfq.side)}
                  {this.convertPrice(rfq.side, rfq.price, rfq.ccy)}
                  <td>
                    <p>
                      {toCurrency(parseFloat(rfq.size) - parseFloat(rfq.filled))}
                    </p>
                    <p>{'(' + toCurrency(rfq.size) + ')'}</p>
                  </td>
                  <td>{rfq.valuedate.slice(0, 10)}</td>
                  <td>
                  <div className="rfq-chng">
                        <button type="button" className="btn btn-primary btn-sm btn-sell" onClick={() => this.setState({rfqid: rfq.id, currentRfq: rfq})} data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#quoteModal">{t('rfq.response')}</button>
                      </div>
                  </td>
                </tr>
                {rfq.quotes.map(q => (
                  <tr className="quote" key={q.id}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{toCurrency(q.price)}</td>
                    <td>{toCurrency(q.size)}</td>
                    <td>{rfq.valuedate.slice(0, 10)}</td>
                    <td></td>
                  </tr>
                ))}
                                <option value="SUU-MN-3028037833100">SUU-MN-3028037833100</option>
                                <option value="APU-MN-1030000033311">APU-MN-1030000033311</option>
                                <option value="GACH-MN-2103000003133">GACH-MN-2103000003133</option>
                  
              </>
            )}
          )} */}
          <tr key={1}>
          <td>APU-MN-1030000033311</td>
          <td>Хоёрдогч</td>
          <td className="buy">Зарах</td>
          <td>17.5</td>
          <td>200,000,000</td>
          <td>2021-05-17</td>
          <td>
            <div className="rfq-chng">
              <button type="button" className="btn btn-primary btn-sm btn-sell" onClick={() => this.setState({rfqid: rfq.id, currentRfq: rfq})} data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#quoteModal">{t('rfq.response')}</button>
            </div>
          </td>
          </tr>


          <tr className="quote" key={2}>
                    <td></td>
                    <td></td>
                    <td>Хоёрдогч</td>
                    <td>17.6</td>
                    <td>200,000,000</td>
                    <td>2021-05-17</td>
                    <td></td>
                  </tr>


                  <tr className="quote" key={2}>
                    <td></td>
                    <td></td>
                    <td>Хоёрдогч</td>
                    <td>17.7</td>
                    <td>200,000,000</td>
                    <td>2021-05-17</td>
                    <td></td>
                  </tr>

          <tr key={1}>
          <td>SUU-MN-3028037833100</td>
          <td>Хоёрдогч</td>
          <td className="sell">Авах</td>
          <td>18</td>
          <td>100,000,000</td>
          <td>2021-05-17</td>
          <td>
            <div className="rfq-chng">
              <button type="button" className="btn btn-primary btn-sm btn-sell" onClick={() => this.setState({rfqid: rfq.id, currentRfq: rfq})} data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#quoteModal">{t('rfq.response')}</button>
            </div>
          </td>
          </tr>


          <tr className="quote" key={3}>
                    <td></td>
                    <td></td>
                    <td>Хоёрдогч</td>
                    <td>18.2</td>
                    <td>100,000,000</td>
                    <td>2021-05-17</td>
                    <td></td>
                  </tr>

                  <tr className="quote" key={3}>
                    <td></td>
                    <td></td>
                    <td>Хоёрдогч</td>
                    <td>18.5</td>
                    <td>100,000,000</td>
                    <td>2021-05-17</td>
                    <td></td>
                  </tr>

                  <tr key={1}>
          <td>GACH-MN-2103000003133</td>
          <td>Хоёрдогч</td>
          <td className="sell">Авах</td>
          <td>18</td>
          <td>100,000,000</td>
          <td>2021-05-17</td>
          <td>
            <div className="rfq-chng">
              <button type="button" className="btn btn-primary btn-sm btn-sell" onClick={() => this.setState({rfqid: rfq.id, currentRfq: rfq})} data-toggle="modal" data-backdrop="static" data-keyboard="false" data-target="#quoteModal">{t('rfq.response')}</button>
            </div>
          </td>
          </tr>


          <tr className="quote" key={3}>
                    <td></td>
                    <td></td>
                    <td>Хоёрдогч</td>
                    <td>18.2</td>
                    <td>100,000,000</td>
                    <td>2021-05-17</td>
                    <td></td>
                  </tr>

                  <tr className="quote" key={3}>
                    <td></td>
                    <td></td>
                    <td>Хоёрдогч</td>
                    <td>18.5</td>
                    <td>100,000,000</td>
                    <td>2021-05-17</td>
                    <td></td>
                  </tr>
          </tbody>
        </table>
        <div className="modal" id="quoteModal">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{t('order.sendquote')}</h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-5 confirm-main">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.ccy')}</p>
                                        <p className="value-text">{this.state.currentRfq.ccy}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.amount')}</p>
                                        <input 
                                        type="text" 
                                        onChange={this._handleChange} 
                                        autoComplete="off"  
                                        name="size"
                                        value={this.state.size}
                                        className="form-control form-control-sm" 
                                        id="size" 
                                        placeholder={t('order.amount')} />
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.price')}</p>
                                        <input 
                                        type="text" 
                                        onChange={this._handleChange} 
                                        autoComplete="off"  
                                        name="price" 
                                        value={this.state.price}
                                        className="form-control form-control-sm" 
                                        id="price" 
                                        placeholder={t('order.price')} />
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">Чат</p>
                                        <input 
                                        type="text" 
                                        onChange={this._handleChange} 
                                        autoComplete="off"  
                                        name="chat" 
                                        value={this.state.description}
                                        className="form-control form-control-sm" 
                                        id="chat" 
                                        placeholder="Чат" />
                                    </div>
                                </div>
                                <div className="col-md-5 confirm-sub">
                                    <div className="confirm">
                                        <p className="title-text">{t('order.instrument')}</p>
                                        <p className="value-text">{t('order.spot')}</p>
                                    </div>
                                    <div className="confirm">
                                        <p className="title-text">{t('order.valuedate')}</p>
                                        <p className="value-text">{this.state.currentRfq.valuedate && this.state.currentRfq.valuedate.slice(0, 10)}</p>
                                    </div>
                                </div>
                            </div>
                            {twofa}
                            <div className="row">
                                <button type="button" className="btn btn-place-order-cancel" data-toggle="modal" data-target="#quoteModal" onClick={() => this.setState({twofa: false, price: '0', size: '0'})}>{t('order.cancel')}</button>
                                <button type="button" className="btn btn-place-order-confirm" data-toggle="modal" onClick={this.placeorder}>{t('order.confirm')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			</div>
    );
  }
}

const mapStateToProps = (state) => ({
  rfq: state.rfq.rfqs,
  quote: state.rfq.quotes,
  user: state.auth.user,
  code: state.auth.code
})
export default connect(mapStateToProps, {createQuote, send2FA})(translate(RFQBoard));