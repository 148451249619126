import axios from 'axios';
import { RFQ_LIST, NEW_RFQ, GET_ERRORS, RFQ_ADD, ACCOUNT_BALANCE, TWO_FACTOR } from './action_types';

export const send2FA = (order) => dispatch =>{
    console.log(order);
    axios.post('https://prod.api.bourse.mn/order/email', order)
            .then(res => {
                dispatch(twofactor(res.data));
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                });
            });
}

export const getAccountBalance = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/nomura')
            .then(res => {
                dispatch(AccountBalance(res.data));
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                });
            });
}

export const getRFQ = () => dispatch =>{
    axios.get('https://prod.api.bourse.mn/data/rfq')
            .then(res => {
                dispatch(RFQList(res.data.message));
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                });
            });
}

export const newRFQ = (order) => dispatch => {
    axios.post('https://prod.api.bourse.mn/order/new', order)
            .then(res => {
                dispatch(newOrder(res.message));
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
            });
}

export const newMarket = (order) => dispatch => {
    axios.post('https://prod.api.bourse.mn/order/market', order)
            .then(res => {
                dispatch(newOrder(res.message));
            })
            .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                });
            });
}

export const AddRFQ = (order) => dispatch => {
    dispatch(RFQAdd(order))
}

export const newOrder = order => {
    return {
        type: NEW_RFQ,
        payload: order
    }
}

export const RFQList = orders => {
    return {
        type: RFQ_LIST,
        payload: orders
    }
}

export const RFQAdd = order => {
    return {
        type: RFQ_ADD,
        payload:order
    }
}

export const AccountBalance = balance => {
    return {
        type: ACCOUNT_BALANCE,
        payload: balance
    }
}

export const twofactor = code => {
    return {
        type: TWO_FACTOR,
        payload: code
    }
}
