import React, { Component } from 'react'; //eslint-disable-line no-unused-vars

import Users from '../components/dashboard/Users';
import Summary from '../components/dashboard/Summary';
import TradeSummary from '../components/dashboard/TradeSummary';


import Header from '../components/dashboard/Header';



import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import Loading from 'react-loading-spinkit';

import { getAllUser } from '../actions/admin';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import Profile from './Profile';



class AdminDashboard extends Component {

	state = {
		selected: 'home',
		expanded: false
	}

	componentDidMount(){
		if(!this.props.isAuthenticated) {
			this.props.history.push('/login');
        }
		this.props.getAllUser()
	}

  render () {
	if(this.props.users.length === 0)
	return (
		<div style={{ height: '100vh', width: '100vw' }}>
			<Loading show={true} />
		</div>
	);
	const marginClass = this.state.expanded ? 'adminextra' : 'admin'
	let content = <div style={{height: '100vh'}}><Profile></Profile></div>;
	if(this.state.selected === 'rfqs')
	content = <div style={{height: '100vh'}}><Summary/></div>;
	if(this.state.selected === 'trades')
	content = <div style={{height: '100vh'}}><TradeSummary/></div>;
	if(this.state.selected === 'orders')
	content = <div style={{height: '100vh'}}><Summary/></div>;
	return (
        <div className={this.props.theme}>
		<div className="dashboard-page">
			<SideNav
				onSelect={(selected) => {
					this.setState({selected})
				}}
				onToggle={(expanded) => {
					this.setState({ expanded });
				}}
			>
				<SideNav.Toggle />
				<SideNav.Nav defaultSelected="home">
					<NavItem eventKey="home">
						<NavIcon>
							<i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText>
							Home
						</NavText>
					</NavItem>
					<NavItem eventKey="rfqs">
						<NavIcon>
							<i className="fa fa-fw fa-comments" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText>
							RFQs
						</NavText>
					</NavItem>
					<NavItem eventKey="trades">
						<NavIcon>
							<i className="fa fa-fw fa-exchange-alt" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText>
							Trades
						</NavText>
					</NavItem>
					<NavItem eventKey="orders">
						<NavIcon>
							<i className="fa fa-fw fa-clipboard" style={{ fontSize: '1.75em' }} />
						</NavIcon>
						<NavText>
							Orders
						</NavText>
					</NavItem>
				</SideNav.Nav>
			</SideNav>
			<div className={marginClass}>
			{this.props.user.status === 'inactive' && <div className="newUser" > Бүртгэл идэвхигүй байна. Нууц үгээ солиж идэвхижүүлнэ үү.</div> }
			<Header/>
			<div className="dashheight">
			{content}
			</div>
			</div>
		</div>
		</div>
	);
  }
}

const mapStateToProps = state => ({
	users: state.admin.users,
	isAuthenticated: state.auth.isAuthenticated,
	theme: state.auth.theme,
	user: state.auth.user

});

export default connect (mapStateToProps, {getAllUser})(withRouter(AdminDashboard));