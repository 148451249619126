import { GET_USER, CANCEL_RFQ, CREATE_RFQ, GET_USER_ACCOUNTS, CREATE_TRADE, CONFIRM_QUOTE, CREATE_QUOTE, CREATE_ORDER} from "../actions/action_types";

const initialState = {
    orders:[],
    trades:[],
    quotes: [],
    clobs: [],
    balance:[
        {ccy: 'mnt', total:0, available: 0},
        {ccy: 'usd', total:0, available: 0},
        {ccy: 'cnh', total:0, available: 0},
        {ccy: 'jpy', total:0, available: 0},
        {ccy: 'eur', total:0, available: 0}
    ],
    accounts: []
}

export default function(state = initialState, action){
    
    switch(action.type){
        case CREATE_RFQ:
        return {
            ...state,
            orders: [...state.orders, action.payload]
        }
        case CREATE_TRADE:
            return{
                ...state, trades: [...state.trades, action.payload],
                quotes: state.quotes.map( o => o.id === action.payload.makerorderid ? {...o, status: "FILLED"} : o),
            };
        case GET_USER:
            console.log(action.payload);
        return {
            ...state, 
            orders: action.payload.orders, 
            trades: action.payload.trades,
            balance: action.payload.balance,
            quotes: action.payload.quotes,
            clobs: action.payload.clobs
        };
        case GET_USER_ACCOUNTS:
        return {
            ...state,
            accounts: action.payload
        }
        case CANCEL_RFQ:
        return {
            ...state,
            orders: state.orders.map( o => o.id === action.payload.id ? action.payload : o),
            quotes: state.quotes.map( o => o.id === action.payload.id ? action.payload : o),
            clobs: state.clobs.map( o => o.id === action.payload.id ? action.payload : o)
        }
        case CONFIRM_QUOTE:
            const index = state.orders.findIndex(x => x.id === action.payload.rfq.id)
            state.orders[index] = action.payload.rfq;
            const orders = [...state.orders];
            return {
                ...state,
                orders,
            }
        case CREATE_QUOTE:
            return {
                ...state, quotes: [...state.quotes, action.payload.rfq]
            }
        case CREATE_ORDER:
            return {
                ...state, orders:[...state.orders, action.payload]
            }
        default:
            return state;
    }
}