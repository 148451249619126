import axios from 'axios';

import { SET_CURRENT_USER, ERROR_USER, CHANGE_THEME } from './action_types';
import setAuthToken from '../setAuthToken';
import jwt_decode from 'jwt-decode'

export const loginUser = (user) => dispatch => {
    axios.post('https://prod.api.bourse.mn/auth', user)
            .then(res => {
                console.log(res.data);
                const {jwt} = res.data;
                localStorage.setItem('jwtToken',jwt)
                setAuthToken(jwt)
                const decoded = jwt_decode(jwt);
                dispatch(setCurrentUser(decoded));
            })
            .catch(err => {
                dispatch(errorUser());
            });
}

export const changeTheme = theme => dispatch => {
    dispatch(change(theme));
}

export const change = theme => {
    return {
        type: CHANGE_THEME,
        payload: theme
    }
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}

export const errorUser = () => {
    return {
        type: ERROR_USER,
    }
}

export const logoutUser = (history) => dispatch => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('status');
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    history.push('login');
}