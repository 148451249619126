import { CREATE_RFQ, GET_RFQ, CREATE_QUOTE, CONFIRM_QUOTE, GET_QUOTE, CANCEL_RFQ, CREATE_TRADE} from "../actions/action_types";

const initialState = {
    rfqs:[],
    quotes:[],
}

export default function(state = initialState, action){
    
    switch(action.type){
        case CREATE_TRADE:
        var rfq = state.rfqs.find(x => x.id === action.payload.takerorderid)
        if ((rfq.size - rfq.filled) - action.payload.size > 0)
            var rfqsNew = state.rfqs.map( x => x.id === action.payload.takerorderid ? {...x, filled: (parseFloat(x.filled) + parseFloat(action.payload.size)).toString()}: x)
        else
            var rfqsNew = state.rfqs.filter( o => o.id !== action.payload.takerorderid)
        return{
            ...state,
            quotes: state.quotes.filter( o => o.id !== action.payload.makerorderid),
            rfqs: rfqsNew
        };
        case CREATE_RFQ:
            return {
                ...state,
                rfqs: [...state.rfqs, action.payload]
            }
        case GET_RFQ:
            return {
                ...state,rfqs: action.payload
            }
        case CREATE_QUOTE:
            return {
                ...state,quotes: [...state.quotes, action.payload.quote]
            }
        case CONFIRM_QUOTE:
            let rfqs;
            if(action.payload.rfq.status === 'FILLED')
                rfqs = state.rfqs.filter(x => x.id !== action.payload.rfq.id)
            else {
                const index = state.rfqs.findIndex(x => x.id === action.payload.rfq.id)
                state.rfqs[index] = action.payload.rfq;
                rfqs = [...state.rfqs];
            }
            return {
                ...state,
                rfqs: rfqs,
                quotes: state.quotes.filter(x => x.id !== action.payload.quote.id)
            }
        case GET_QUOTE:
            return {
                ...state,quotes: action.payload
            }
        case CANCEL_RFQ:
        return {
            ...state,
            quotes: state.quotes.filter( r => r.id !== action.payload.id),
            rfqs: state.rfqs.map( r => r.id === action.payload.id ? action.payload : r)
        }
        default:
            return state;
    }
}