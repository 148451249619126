import React, { Component } from 'react';
import 'react-reflex/styles.css'
import { connect } from 'react-redux';
import toCurrency from '../../utils/currency';
import { translate } from 'react-switch-lang';

class CLOB extends Component {

    convertOwntradeSell = (c) => {
        const { user } = this.props;
        const commision = this.props.ccy === 'usd' ? 0.25 : 0.10;
        if(c.userid === user.user_id) {
            return <tr className="row-sell">
            <td>{toCurrency(parseFloat(c.price))}</td>
            <td>{toCurrency(parseFloat(c.size))}</td>
            <td>*</td>

        </tr>
        }
        else {
            return <tr className="row-sell">
                        <td>{toCurrency(parseFloat(c.price) - commision)}</td>
                        <td>{toCurrency(parseFloat(c.size))}</td>
                        <td></td>
                    </tr>
        }
    }

    convertOwntradeBuy = (c) => {
        const { user } = this.props;
        const commision = this.props.ccy === 'usd' ? 0.25 : 0.10;
        console.log(user.user_id);
        console.log(c.userid);
        if(c.userid === user.user_id) {
            return <tr className="row-buy">
            <td>{toCurrency(parseFloat(c.price))}</td>
            <td>{toCurrency(parseFloat(c.size))}</td>
            <td>*</td>

        </tr>
        }
        else {
            return <tr className="row-buy">
                        <td>{toCurrency(parseFloat(c.price) + commision)}</td>
                        <td>{toCurrency(parseFloat(c.size))}</td>
                        <td></td>
                    </tr>
        }
    }

    render() {
        // const usd1 = [{price: 2693, amount: 500000},{price: 2690, amount: 100000},{price: 2689, amount: 800000},{price: 2689.50, amount: 500000},{price: 2689.25, amount: 100000}];
        // const usd2 = [{price: 2700, amount: 100000},{price: 2700.50, amount: 300000},{price: 2701, amount: 150000},{price: 2703, amount: 100000},{price: 2705, amount: 500000}];

        // const cnh1 = [{price: 380.80, amount: 500000},{price: 379.70, amount: 100000},{price: 378.50, amount: 800000},{price: 378.20, amount: 500000},{price: 378.10, amount: 100000}];
        // const cnh2 = [{price: 385.60, amount: 100000},{price: 385.90, amount: 300000},{price: 386.00, amount: 150000},{price: 386.50, amount: 100000},{price: 386.90, amount: 500000}];
        
        // const eur1 = [{price: 2693, amount: 500000},{price: 2690, amount: 100000},{price: 2689, amount: 800000},{price: 2689.50, amount: 500000},{price: 2689.25, amount: 100000}];
        // const eur2 = [{price: 2700, amount: 100000},{price: 2700.50, amount: 300000},{price: 2701, amount: 150000},{price: 2703, amount: 100000},{price: 2705, amount: 500000}];

        // const usd1 = [{price: 2693, amount: 500000},{price: 2690, amount: 100000},{price: 2689, amount: 800000},{price: 2689.50, amount: 500000},{price: 2689.25, amount: 100000}];
        // const usd2 = [{price: 2700, amount: 100000},{price: 2700.50, amount: 300000},{price: 2701, amount: 150000},{price: 2703, amount: 100000},{price: 2705, amount: 500000}];

        let clob = [];
        let clob2 = [];
        
        if(this.props.ccy === 'usd'){
            if(this.props.usdsell && this.props.usdbuy) {
                clob = this.props.usdsell.filter(x => x.status === "OPEN" )
                clob2 = this.props.usdbuy.filter(x => x.status === "OPEN" )
            }
        }
        if(this.props.ccy === 'cnh'){
            if(this.props.cnhsell && this.props.cnhbuy) {
                clob = this.props.cnhsell.filter(x => x.status === "OPEN" )
                clob2 = this.props.cnhbuy.filter(x => x.status === "OPEN" )
            }
        }
        
        const { t } = this.props;

        return (
            <div className="component-tab flex">
                {/* <div className="clob-flex">
                    <table className="clob-table">
                        <thead>
                            <tr className="buy">
                                <td>{t('order.price')}</td>
                                <td>{t('order.amount')}</td>
                                <td></td>

                            </tr>
                        </thead>
                        <tbody className="clob-sell">
                            {clob.map(c => {
                                return this.convertOwntradeSell(c);
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='clob-seperator'>    
                </div> */}
                <div className="clob-flex">
                <table className="clob-table">
                        <thead>
                            <tr className="sell">
                                <td>{t('order.price')}</td>
                                <td>Дүн</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody className="clob-buy">
                        {/* {clob2.reverse().map(c => {
                                return this.convertOwntradeBuy(c);
                            })} */}
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 17.5</td>
                                <td>100,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 17.6</td>
                                <td>150,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18</td>
                                <td>200,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18</td>
                                <td>225,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18</td>
                                <td>215,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18</td>
                                <td>235,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18</td>
                                <td>245,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18</td>
                                <td>246,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18</td>
                                <td>247,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18.5</td>
                                <td>250,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18.6</td>
                                <td>300,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18.7</td>
                                <td>300,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18.9</td>
                                <td>300,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18.9</td>
                                <td>300,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18.9</td>
                                <td>300,000,000</td>
                            </tr>
                            <tr className="row-buy">
                                <td>SUU@20210517-18 / 18.9</td>
                                <td>300,000,000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
  }
}

const mapStateToProps = (state) => ({
    usdsell: state.clob.clob.usdsell,
    usdbuy: state.clob.clob.usdbuy,
    cnhsell: state.clob.clob.cnhsell,
    cnhbuy: state.clob.clob.cnhbuy,
    user: state.auth.user
})
export default connect(mapStateToProps, {})(translate(CLOB));